import React from 'react';
import { Checkbox } from '@material-ui/core';
import UIDataTableBodyCell from './DataTableBodyCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';

export const UIDataTableBodyCellCheckbox = ({
  isChecked,
  isVisible,
  handleChange
}) => {
  return (
    <UIDataTableBodyCell width="xs" padding="none">
      <UIFlexboxCenterPositioner>
        {!isVisible ? null : (
          <Checkbox
            checked={isChecked}
            onChange={event => {
              return isVisible ? handleChange(event) : null;
            }}
          />
        )}
      </UIFlexboxCenterPositioner>
    </UIDataTableBodyCell>
  );
};

export default UIDataTableBodyCellCheckbox;
