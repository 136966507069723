import React, { useCallback } from 'react';
import { TableRow } from '@material-ui/core';
import UIDataTableBodyCellRecordField from '../UI/DataTableBodyCellRecordField';
import { FIELD_GROUP_ALX_FIELDS } from '../../consts';

const RenewalDataTableClaimsDataRow = ({
  compositeRecord,
  stateClaimDataTableAlxColumns
}) => {
  const handleAlxFieldChange = useCallback((/*fieldName */) => {}, []);

  return (
    <TableRow>
      {stateClaimDataTableAlxColumns.map(
        ({ columnVisible, columnWidth, fieldName }, index) => {
          const fieldConfig =
            compositeRecord[FIELD_GROUP_ALX_FIELDS][fieldName];

          return (
            <React.Fragment key={`unlocked-column-td-cell-${index}`}>
              {columnVisible ? (
                <UIDataTableBodyCellRecordField
                  {...{
                    columnWidth,
                    ...fieldConfig,
                    handleFieldChange: handleAlxFieldChange
                  }}
                />
              ) : null}
            </React.Fragment>
          );
        }
      )}
    </TableRow>
  );
};

export default RenewalDataTableClaimsDataRow;
