export const REMOVE_PROPERTIES_FROM_SELECTED =
  'REMOVE_PROPERTIES_FROM_SELECTED';
export const RESET_PROPERTIES_DATATABLE = 'RESET_PROPERTIES_DATATABLE';
export const SET_CURRENT_PROPERTIES_PAGE = 'SET_CURRENT_PROPERTIES_PAGE';
export const SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE =
  'SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE';
export const SET_OPENED_PROPERTY = 'SET_OPENED_PROPERTY';
export const TOGGLE_OPENED_PROPERTY = 'TOGGLE_OPENED_PROPERTY';
export const TOGGLE_SELECTED_PROPERTY = 'TOGGLE_SELECTED_PROPERTY';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const CLOSE_OPENED_PROPERTY = 'CLOSE_OPENED_PROPERTY';
