import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { animated, useSpring, useTransition } from 'react-spring';
import { Box, Container } from '@material-ui/core';
import UILoadingScreen from '../../components/UI/LoadingScreen';
import UISnackbar from '../../components/UI/Snackbar';
import RenewalUserInfoBar from '../../components/Renewal/UserInfoBar';
import RenewalDataTableUnits from '../../components/Renewal/DataTableUnits';
import RenewalDataTableProperties from '../../components/Renewal/DataTableProperties';
import RenewalDataTableClaims from '../../components/Renewal/DataTableClaims';
import RenewalMessagesTable from '../../components/Renewal/MessagesTable';
import RenewalActionsBarSingleProperty from '../../components/Renewal/ActionsBarSingleProperty';
import RenewalActionsBarMultipleProperties from '../../components/Renewal/ActionsBarMultipleProperties';
import RenewalAssetDocumentStatus from '../../components/Renewal/AssetDocumentStatus';
import NotFound404 from '../../components/NotFound404';

const RenewalView = ({
  actionChangePropertyRecordFieldValue,
  actionChangeUnitRecordFieldValue,
  actionCloseOpenedProperty,
  actionCloseSnackBar,
  actionResetPropertyRecordAndItsUnitRecords,
  actionSetCurrentClaimsPage,
  actionSetCurrentPropertiesPage,
  actionSetCurrentUnitsPage,
  actionSetDataTablesFilterValue,
  actionSetNumOfClaimsToShowPerPage,
  actionSetNumOfPropertiesToShowPerPage,
  actionSetNumOfUnitsToShowPerPage,
  actionSetOpenedProperty,
  actionSetSelectedProperties,
  actionToggleOpenedProperty,
  actionToggleSelectedProperty,
  actionSetDataTablesCheckedFilterValue,
  actionSetFloatIncrease,
  stateApiCallInprogress,
  stateChangedRecordIndexes,
  stateClaimDataTableAlxColumns,
  stateCompositeClaimRecordsToShow,
  stateCompositePropertyRecordsToShow,
  stateCompositeUnitRecordsToShow,
  stateCurrentClaimsPage,
  stateCurrentPropertiesPage,
  stateCurrentUnitsPage,
  stateDataTablesFilter,
  stateEditModesBasedOnState,
  stateIsPropertyOpen,
  stateNumOfClaimRecordsPerPage,
  stateNumOfPropertyRecordsPerPage,
  stateNumOfTotalClaimRecords,
  stateNumOfTotalPropertyRecords,
  stateNumOfTotalUnitRecords,
  stateNumOfUnitRecordsPerPage,
  stateOpenedPropertyIndex,
  statePropertyDataTableAlxColumns,
  statePropertyDataTableCoordinatorColumns,
  stateRenewalUser,
  stateSelectedProperties,
  stateShowNewCommentBox,
  stateShowOldMessages,
  stateSnackBarMessage,
  stateSnackBarOpen,
  stateSnackBarVariant,
  stateUnitDataTableAlxColumns,
  role,
  // TODO REMOVE THESE VIA REDUX SELECTORS */
  stateChangedRenewalRecords,
  stateUpdatedRenewalRecords,
  actionSubmitRecordChangesForEncryptedResource,
  stateEncryptedResource,
  stateFloat,
  stateFilteredIndexes,
  show404
  /*
    stateNewMessageIndicatorBasedOnState,
    stateNumericsToFieldTypes,
    statePropertyFieldConfigsBasedOnState*/
}) => {
  useEffect(() => {
    if (stateFloat) {
      actionSetFloatIncrease(stateFilteredIndexes, stateFloat);
    }
  }, [stateFloat]);

  const transitions = useTransition(stateApiCallInprogress, null, {
    from: { position: 'absolute', opacity: 0, width: '99%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const primarySpring = useSpring({
    width:
      role === 'HANDLER'
        ? stateIsPropertyOpen
          ? '75%'
          : '100%'
        : stateShowNewCommentBox || stateShowOldMessages
        ? '75%'
        : '100%'
  });
  const secondarySpring = useSpring({
    width:
      role === 'HANDLER'
        ? stateIsPropertyOpen
          ? '25%'
          : '0%'
        : stateShowNewCommentBox || stateShowOldMessages
        ? '25%'
        : '0%',
    overflowX:
      role === 'HANDLER'
        ? 'visible'
        : stateShowNewCommentBox || stateShowOldMessages
        ? 'visible'
        : 'hidden'
  });

  if (show404) {
    return <NotFound404 />;
  }

  return (
    <React.Fragment>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div style={props} key={`loading_screen_${key}`}>
            <UILoadingScreen />
          </animated.div>
        ) : (
          <animated.div style={props} key={`renewal_dashbaord_${key}`}>
            <Container maxWidth={false}>
              <Box display="flex" width="100%" style={{ flexFlow: 'column' }}>
                <Box padding="1em">
                  <RenewalUserInfoBar
                    {...{
                      requestType: stateRenewalUser['request'],
                      requesterType: stateRenewalUser['requester'],
                      requesterId: stateRenewalUser['id'],
                      requesterName: stateRenewalUser['name'],
                      requesterEmail: stateRenewalUser['email']
                    }}
                  />
                </Box>

                <Box width="100%">
                  <Box display="flex" width="100%">
                    <animated.div style={primarySpring}>
                      <Box className="main-table-container" padding="1em">
                        {typeof stateOpenedPropertyIndex === 'number' &&
                        stateUpdatedRenewalRecords[stateOpenedPropertyIndex]
                          .property.alx_fields.field_renewal_status ===
                          'With handler for agreed scheme updates' ? null : (
                          <RenewalActionsBarSingleProperty
                            {...{
                              actionCloseOpenedProperty,
                              actionResetPropertyRecordAndItsUnitRecords,
                              actionSubmitRecordChangesForEncryptedResource,
                              stateApiCallInprogress,
                              stateChangedRecordIndexes,
                              stateChangedRenewalRecords,
                              stateEncryptedResource,
                              stateIsPropertyOpen,
                              stateOpenedPropertyIndex,
                              stateUpdatedRenewalRecords
                            }}
                          />
                        )}
                        <RenewalDataTableProperties
                          {...{
                            actionChangePropertyRecordFieldValue,
                            actionResetPropertyRecordAndItsUnitRecords,
                            actionSetCurrentPropertiesPage,
                            actionSetDataTablesFilterValue,
                            actionSetNumOfPropertiesToShowPerPage,
                            actionSetOpenedProperty,
                            actionSetSelectedProperties,
                            actionToggleOpenedProperty,
                            actionToggleSelectedProperty,
                            actionSetDataTablesCheckedFilterValue,
                            stateChangedRecordIndexes,
                            stateCompositePropertyRecordsToShow,
                            stateCurrentPropertiesPage,
                            stateDataTablesFilter,
                            stateEditModesBasedOnState,
                            stateIsPropertyOpen,
                            stateNumOfPropertyRecordsPerPage,
                            stateNumOfTotalPropertyRecords,
                            statePropertyDataTableAlxColumns,
                            statePropertyDataTableCoordinatorColumns,
                            stateSelectedProperties,
                            stateUpdatedRenewalRecords,
                            stateRenewalUser
                          }}
                        />
                        <RenewalDataTableUnits
                          {...{
                            actionChangeUnitRecordFieldValue,
                            actionSetCurrentUnitsPage,
                            actionSetNumOfUnitsToShowPerPage,
                            stateCompositeUnitRecordsToShow,
                            stateCurrentUnitsPage,
                            stateIsPropertyOpen,
                            stateNumOfTotalUnitRecords,
                            stateNumOfUnitRecordsPerPage,
                            stateOpenedPropertyIndex,
                            stateUnitDataTableAlxColumns
                          }}
                        />
                        <RenewalDataTableClaims
                          {...{
                            actionSetCurrentClaimsPage,
                            actionSetNumOfClaimsToShowPerPage,
                            stateClaimDataTableAlxColumns,
                            stateCompositeClaimRecordsToShow,
                            stateCurrentClaimsPage,
                            stateIsPropertyOpen,
                            stateNumOfClaimRecordsPerPage,
                            stateNumOfTotalClaimRecords
                          }}
                        />
                        {typeof stateOpenedPropertyIndex === 'number' &&
                        stateUpdatedRenewalRecords[stateOpenedPropertyIndex]
                          .property.alx_fields.field_renewal_status ===
                          'With handler for agreed scheme updates' ? null : (
                          <RenewalActionsBarSingleProperty
                            {...{
                              actionCloseOpenedProperty,
                              actionResetPropertyRecordAndItsUnitRecords,
                              actionSubmitRecordChangesForEncryptedResource,
                              stateApiCallInprogress,
                              stateChangedRecordIndexes,
                              stateChangedRenewalRecords,
                              stateEncryptedResource,
                              stateIsPropertyOpen,
                              stateOpenedPropertyIndex,
                              stateUpdatedRenewalRecords
                            }}
                          />
                        )}
                        <RenewalActionsBarMultipleProperties
                          {...{
                            actionSubmitRecordChangesForEncryptedResource,
                            stateApiCallInprogress,
                            stateChangedRenewalRecords,
                            stateEncryptedResource,
                            stateIsPropertyOpen,
                            stateSelectedProperties,
                            stateUpdatedRenewalRecords
                          }}
                        />
                      </Box>
                    </animated.div>
                    <animated.div style={secondarySpring}>
                      <Box padding="1em">
                        <RenewalMessagesTable
                          {...{
                            actionChangePropertyRecordFieldValue,
                            stateChangedRenewalRecords,
                            stateOpenedPropertyIndex,
                            stateShowNewCommentBox,
                            stateShowOldMessages,
                            stateUpdatedRenewalRecords,
                            requesterType: stateRenewalUser['requester'],
                            requesterName: stateRenewalUser['name']
                          }}
                        />
                      </Box>
                      {(role === 'HANDLER' || role === 'ADDS') && (
                        <Box padding="1rem">
                          <RenewalAssetDocumentStatus
                            {...{
                              stateOpenedPropertyIndex,
                              stateUpdatedRenewalRecords
                            }}
                          />
                        </Box>
                      )}
                    </animated.div>
                  </Box>
                </Box>
              </Box>
            </Container>
          </animated.div>
        )
      )}
      <UISnackbar
        {...{
          handleClose: actionCloseSnackBar,
          isOpen: stateSnackBarOpen,
          message: stateSnackBarMessage,
          type: stateSnackBarVariant
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  role: state.renewal.user.requester
});

export default connect(mapStateToProps)(RenewalView);
