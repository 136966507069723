import React from 'react';
import { connect } from 'react-redux';

import actionChangePropertyRecordFieldValue from '../../store/actions/changePropertyRecordFieldValue.action.js';
import actionChangeUnitRecordFieldValue from '../../store/actions/changeUnitRecordFieldValue.action.js';
import actionCloseOpenedProperty from '../../store/actions/closeOpenedProperty.action.js';
import actionCloseSnackBar from '../../store/actions/closeSnackBar.action.js';
import actionGetRecordsAndConfigsForEncryptedResource from '../../store/actions/getRecordsAndConfigsForEncryptedResource.action.js';
import actionResetDataTablesFilter from '../../store/actions/resetDataTablesFilter.action.js';
import actionResetPropertyRecordAndItsUnitRecords from '../../store/actions/resetPropertyRecordAndItsUnitRecords.action.js';
import actionSetCurrentClaimsPage from '../../store/actions/setCurrentClaimsPage.action.js';
import actionSetCurrentPropertiesPage from '../../store/actions/setCurrentPropertiesPage.action.js';
import actionSetCurrentUnitsPage from '../../store/actions/setCurrentUnitsPage.action.js';
import actionSetDataTablesFilterValue from '../../store/actions/setDataTablesFilterValue.action.js';
import actionSetDataTablesCheckedFilterValue from '../../store/actions/setDataTablesCheckedFilterValue.action.js';
import actionSetNumOfClaimsToShowPerPage from '../../store/actions/setNumOfClaimsToShowPerPage.action.js';
import actionSetNumOfPropertiesToShowPerPage from '../../store/actions/setNumOfPropertiesToShowPerPage.action.js';
import actionSetNumOfUnitsToShowPerPage from '../../store/actions/setNumOfUnitsToShowPerPage.action.js';
import actionSetOpenedProperty from '../../store/actions/setOpenedProperty.action.js';
import actionSetSelectedProperties from '../../store/actions/setSelectedProperties.action.js';
import actionSubmitRecordChangesForEncryptedResource from '../../store/actions/submitRecordChangesForEncryptedResource.action.js';
import actionToggleOpenedProperty from '../../store/actions/toggleOpenedProperty.action.js';
import actionToggleSelectedProperty from '../../store/actions/toggleSelectedProperty.action.js';
import actionSetFloatIncrease from '../../store/actions/setFloatIncrease.action.js';

import selectorChangedRecordIndexes from '../../store/selectors/changedRecordIndexes.selector';
import selectorClaimDataTableAlxColumns from '../../store/selectors/claimDataTableAlxColumns.selector';
import selectorCompositeClaimRecordsToShow from '../../store/selectors/compositeClaimRecordsToShow.selector';
import selectorCompositePropertyRecordsToShow from '../../store/selectors/compositePropertyRecordsToShow.selector';
import selectorCompositeUnitRecordsToShow from '../../store/selectors/compositeUnitRecordsToShow.selector';
import selectorIsPropertyDetailsOpen from '../../store/selectors/isPropertyDetailsOpen.selector';
import selectorNumOfTotalClaimRecords from '../../store/selectors/numOfTotalClaimRecords.selector';
import selectorNumOfTotalPropertyRecords from '../../store/selectors/numOfTotalPropertyRecords.selector';
import selectorNumOfTotalUnitRecords from '../../store/selectors/numOfTotalUnitRecords.selector';
import selectorNumericsToFieldTypes from '../../store/selectors/numericsToFieldTypes.selector';
import selectorPropertyDataTableAlxColumns from '../../store/selectors/propertyDataTableAlxColumns.selector';
import selectorPropertyDataTableCoordinatorColumns from '../../store/selectors/propertyDataTableCoordinatorColumns.selector';
import selectorShowNewCommentBox from '../../store/selectors/showNewCommentBox.selector';
import selectorShowOldMessages from '../../store/selectors/showOldMessages.selector';
import selectorUnitDataTableAlxColumns from '../../store/selectors/unitDataTableAlxColumns.selector';
import selectorFilteredPropertyIndexes from '../../store/selectors/filteredPropertyIndexes.selector';

const mapStateToProps = state => {
  return {
    show404: state.api.show404,
    stateFloat: state.float,
    stateFilteredIndexes: selectorFilteredPropertyIndexes(state),
    stateApiCallInprogress: state.api.callInProgress,
    stateChangedRecordIndexes: selectorChangedRecordIndexes(state),
    stateChangedRenewalRecords: state.renewal.records.changed,
    stateClaimDataTableAlxColumns: selectorClaimDataTableAlxColumns(state),
    stateClaimFieldConfigsBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs
        .claimFieldConfigsBasedOnState,
    stateCompositeClaimRecordsToShow: selectorCompositeClaimRecordsToShow(
      state
    ),
    stateCompositePropertyRecordsToShow: selectorCompositePropertyRecordsToShow(
      state
    ),
    stateCompositeUnitRecordsToShow: selectorCompositeUnitRecordsToShow(state),
    stateCurrentClaimsPage:
      state.renewal.dataTable.claim.pagination.currentPage,
    stateCurrentPropertiesPage:
      state.renewal.dataTable.property.pagination.currentPage,
    stateCurrentUnitsPage: state.renewal.dataTable.unit.pagination.currentPage,
    stateDataTablesFilter: state.renewal.dataTable.property.filter,
    stateEditModesBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs.editModesBasedOnState,
    stateEncryptedResource: state.renewal.access.encryptedResource,
    stateIsPropertyOpen: selectorIsPropertyDetailsOpen(state),
    stateNewMessageIndicatorBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs
        .newMessageIndicatorBasedOnState,
    stateNumOfClaimRecordsPerPage:
      state.renewal.dataTable.claim.pagination.numOfRecordsPerPage,
    stateNumOfPropertyRecordsPerPage:
      state.renewal.dataTable.property.pagination.numOfRecordsPerPage,
    stateNumOfTotalClaimRecords: selectorNumOfTotalClaimRecords(state),
    stateNumOfTotalPropertyRecords: selectorNumOfTotalPropertyRecords(state),
    stateNumOfTotalUnitRecords: selectorNumOfTotalUnitRecords(state),
    stateNumOfUnitRecordsPerPage:
      state.renewal.dataTable.unit.pagination.numOfRecordsPerPage,
    stateNumericsToFieldTypes: selectorNumericsToFieldTypes(state),
    stateOpenedPropertyIndex:
      state.renewal.dataTable.property.pagination.openedPropertyIndex,
    statePropertyDataTableAlxColumns: selectorPropertyDataTableAlxColumns(
      state
    ),
    statePropertyDataTableCoordinatorColumns: selectorPropertyDataTableCoordinatorColumns(
      state
    ),
    statePropertyFieldConfigsBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs
        .propertyFieldConfigsBasedOnState,
    stateRenewalUser: state.renewal.user,
    stateSelectedProperties:
      state.renewal.dataTable.property.pagination.selectedProperties,
    stateShowNewCommentBox: selectorShowNewCommentBox(state),
    stateShowNewMessageBoxBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs
        .showNewMessageBoxBasedOnState,
    stateShowOldMessages: selectorShowOldMessages(state),
    stateSnackBarMessage: state.snackbar.message,
    stateSnackBarOpen: state.snackbar.open,
    stateSnackBarVariant: state.snackbar.variant,
    stateUnitDataTableAlxColumns: selectorUnitDataTableAlxColumns(state),
    stateUnitFieldConfigsBasedOnState:
      state.renewal.dataTable.columnAndRecordConfigs
        .unitFieldConfigsBasedOnState,
    stateUpdatedRenewalRecords: state.renewal.records.updated
  };
};

const mapActionsToProps = {
  actionChangePropertyRecordFieldValue,
  actionChangeUnitRecordFieldValue,
  actionCloseOpenedProperty,
  actionCloseSnackBar,
  actionGetRecordsAndConfigsForEncryptedResource,
  actionResetDataTablesFilter,
  actionResetPropertyRecordAndItsUnitRecords,
  actionSetCurrentClaimsPage,
  actionSetCurrentPropertiesPage,
  actionSetCurrentUnitsPage,
  actionSetDataTablesFilterValue,
  actionSetNumOfClaimsToShowPerPage,
  actionSetNumOfPropertiesToShowPerPage,
  actionSetNumOfUnitsToShowPerPage,
  actionSetOpenedProperty,
  actionSetSelectedProperties,
  actionSubmitRecordChangesForEncryptedResource,
  actionToggleOpenedProperty,
  actionToggleSelectedProperty,
  actionSetDataTablesCheckedFilterValue,
  actionSetFloatIncrease
};

export default function RenewalStore(ToWrapComponent) {
  let WrapperComponent = props => {
    return <ToWrapComponent {...props} />;
  };

  return connect(mapStateToProps, mapActionsToProps)(WrapperComponent);
}
