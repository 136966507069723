import _ from 'lodash';
import moment from 'moment';
import {
    FIELD_GROUP_ALX_FIELDS,
    FIELD_GROUP_COORDINATOR_FIELDS,
    FIELD_GROUP_CUSTOM_FIELDS,
    FIELD_TYPE_DATE,
    FIELD_TYPE_DOUBLE,
    FIELD_TYPE_FREEFORM,
    FIELD_TYPE_LABEL,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_STATIC,
    FIELD_TYPE_TEXTAREA
} from '../consts';

class RenewalRecords {
    constructor() {
        this.generateEmptyChangedRecords = this.generateEmptyChangedRecords.bind(
            this
        );
        this.resetChangedRecord = this.resetChangedRecord.bind(this);
        this.prepareRecordForUpdate = this.prepareRecordForUpdate.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.VALUE_GETTERS = {
            [FIELD_TYPE_FREEFORM]: ({ fieldValue }) => {
                return fieldValue;
            },
            [FIELD_TYPE_DOUBLE]: ({ fieldValue }) => {
                if (_.isNil(fieldValue) || fieldValue === '') {
                    return 0;
                }

                return fieldValue;
            },
            [FIELD_TYPE_SELECT]: ({ fieldValue, fieldSelectsMap }) => {
                return fieldValue ? fieldSelectsMap[fieldValue] : null;
            },
            [FIELD_TYPE_LABEL]: ({ fieldLabel }) => {
                return fieldLabel;
            },
            [FIELD_TYPE_DATE]: ({ fieldValue }) => {
                return _.isNil(fieldValue) || fieldValue === ''
                    ? ''
                    : moment(fieldValue).format('DD/MM/YYYY');
            },
            [FIELD_TYPE_STATIC]: ({ fieldValue }) => {
                return fieldValue;
            },
            [FIELD_TYPE_TEXTAREA]: ({ fieldValue }) => {
                return fieldValue;
            }
        };
    }

    getFieldValue({ fieldType, fieldValue, fieldSelectsMap, fieldLabel }) {
        return this.VALUE_GETTERS[fieldType]({
            fieldValue,
            fieldSelectsMap,
            fieldLabel
        });
    }

    getFieldState(propertyRecord, fieldGroup) {
        return fieldGroup === FIELD_GROUP_COORDINATOR_FIELDS
            ? propertyRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_current_state'
            ]
            : propertyRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_next_state'
            ];
    }

    isInEditMode(propertyRecord, fieldGroup) {
        return (
            fieldGroup === FIELD_GROUP_COORDINATOR_FIELDS ||
            (fieldGroup === FIELD_GROUP_ALX_FIELDS &&
                propertyRecord[FIELD_GROUP_COORDINATOR_FIELDS][
                'coordinator_field_current_state'
                ] !==
                propertyRecord[FIELD_GROUP_COORDINATOR_FIELDS][
                'coordinator_field_next_state'
                ])
        );
    }

    generateEmptyChangedRecord(index, updatedAt) {
        return {
            meta: {
                index: index,
                bUpdated: false,
                updatedAt: updatedAt
            },
            [FIELD_GROUP_COORDINATOR_FIELDS]: {},
            [FIELD_GROUP_ALX_FIELDS]: {},
            [FIELD_GROUP_CUSTOM_FIELDS]: {}
        };
    }

    generateEmptyChangedRecords(renewalRecords) {
        const updatedAt = new Date().getTime();

        return renewalRecords.map((renewalRecord, renewalRecordIndex) => {
            return {
                property: this.generateEmptyChangedRecord(
                    renewalRecordIndex,
                    updatedAt
                ),
                units: renewalRecord['units'].map((unitRecord, unitRecordIndex) => {
                    return this.generateEmptyChangedRecord(unitRecordIndex, updatedAt);
                }),
                claims: renewalRecord['claims'].map((claimRecord, claimRecordIndex) => {
                    return this.generateEmptyChangedRecord(claimRecordIndex, updatedAt);
                })
            };
        });
    }

    resetChangedRecord(changedRecord) {
        const { index, updatedAt } = changedRecord['meta'];
        const resettedRecord = this.generateEmptyChangedRecord(index, updatedAt);

        if (
            Object.keys(changedRecord[FIELD_GROUP_ALX_FIELDS]).length !== 0 ||
            Object.keys(changedRecord[FIELD_GROUP_COORDINATOR_FIELDS]).length !== 0 ||
            Object.keys(changedRecord[FIELD_GROUP_CUSTOM_FIELDS]).length !== 0
        ) {
            resettedRecord['meta']['bUpdated'] = true;
            resettedRecord['meta']['updatedAt'] = new Date().getTime();
        }

        return resettedRecord;
    }

    prepareRecordForUpdate(changedRecord, updatedRecord) {

        let finalUpdatedRecord = null

        if (Object.prototype.hasOwnProperty.call(updatedRecord.coordinator_fields, 'coordinator_field_current_state')) {
            finalUpdatedRecord = {
                ...updatedRecord,
                coordinator_fields: {
                    ...updatedRecord.coordinator_fields,
                    coordinator_field_next_state: 1
                }
            }
            if (Object.prototype.hasOwnProperty.call(finalUpdatedRecord.alx_fields, 'photo')) {
                delete finalUpdatedRecord.alx_fields.photo
            }
        }

        if (Object.prototype.hasOwnProperty.call(changedRecord.alx_fields, 'photo')) {
            delete changedRecord.alx_fields.photo
        }

        const preparedRecord = {
            [FIELD_GROUP_COORDINATOR_FIELDS]: finalUpdatedRecord ? JSON.parse(
                JSON.stringify(finalUpdatedRecord[FIELD_GROUP_COORDINATOR_FIELDS])
            ) :
                JSON.parse(
                    JSON.stringify(updatedRecord[FIELD_GROUP_COORDINATOR_FIELDS])
                ),
            [FIELD_GROUP_ALX_FIELDS]: JSON.parse(
                JSON.stringify(changedRecord[FIELD_GROUP_ALX_FIELDS])
            ),
            [FIELD_GROUP_CUSTOM_FIELDS]: JSON.parse(
                JSON.stringify(changedRecord[FIELD_GROUP_CUSTOM_FIELDS])
            )
        };

        _.forEach(
            changedRecord[FIELD_GROUP_COORDINATOR_FIELDS],
            (fieldValue, fieldName) => {
                preparedRecord[FIELD_GROUP_COORDINATOR_FIELDS][fieldName] = fieldValue;
            }
        );

        return preparedRecord;
    }

    prepareRenewalRecordForUpdate(changedRenewalRecord, updatedRenewalRecord) {

        // if (!changedRenewalRecord['property']['meta']['bUpdated']) {
        //     return null;
        // }

        // console.log(changedRenewalRecord, updatedRenewalRecord);

        const preparedRecord = {

            property: this.prepareRecordForUpdate(
                changedRenewalRecord['property'],
                updatedRenewalRecord['property']
            ),
            units: changedRenewalRecord['units']
                .filter(changedUnitRecord => {
                    return changedUnitRecord['meta']['bUpdated'] === true;
                })
                .map(changedUnitRecord => {
                    const unitRecordIndex = changedUnitRecord['meta']['index'];
                    const updatedUnitRecord = updatedRenewalRecord['units'][unitRecordIndex];
                    return this.prepareRecordForUpdate(changedUnitRecord, updatedUnitRecord);
                })
        };

        return preparedRecord;
    }
}

export default new RenewalRecords();
