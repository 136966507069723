export default {
    colors: {
        primary: /*'#012f6b'*/ '#1d1d1d',
        secondary: '#0085C0',
        tertiary: /*'#108297'*/ /*'#789391'*/ '#1d1d1d',
        quaternary: /*'#222'*/ '#1d1d1d',
        quinary:
      /*'#BE781E'*/ /*'#ab7533'*/ /*'#7A7450'*/ /*'#789391'*/ /*'#9D9439'*/ /*'#A39937'*/ '#021229',
        dark: /*'#222'*/ '#1d1d1d',
        backgroundHighlight: '#F3F8FA',
        inputHighlight: '#f1f1f1',
        bootstrapAlert: {
            warning: {
                background: '#fff3cd',
                text: '#856504',
                border: '#856504'
            },
            error: {
                background: '#f8d7da',
                text: '#82333b',
                border: '#82333b'
            },
            info: {
                background: '#cce5ff',
                text: '#0b498b',
                border: '#0b498b'
            },
            success: {
                background: '#d4edda',
                text: '#4a8057',
                border: '#4a8057'
            }
        },
        snackbar: {
            success: '#42a047',
            error: '#d42f2f',
            warning: '#E6902C'
        }
    },
    dataTables: {
        cellHeight: '50px',
        cellWidth: {
            xs: '80px',
            s: '200px',
            m: '300px',
            l: '600px',
            xl: '1000px'
        }
    }
};
