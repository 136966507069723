import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';
import UIPaperFullWidth from '../UI/PaperFullWidth';
import UIAnimateAutoHeight from '../UI/AnimateAutoHeight';
import config from '../../config';
import services from '../../services';
import { Snackbar } from '../UI';
import { useSelector } from 'react-redux';

const RenewalActionsBarMultipleProperties = ({
  actionSubmitRecordChangesForEncryptedResource,
  stateApiCallInprogress,
  stateChangedRenewalRecords,
  stateEncryptedResource,
  stateIsPropertyOpen,
  stateSelectedProperties,
  stateUpdatedRenewalRecords
}) => {
  const request = useSelector(state => state.renewal.user.request);
  const requester = useSelector(state => state.renewal.user.requester);

  //console.log('actionSubmitRecordChangesForEncryptedResource', actionSubmitRecordChangesForEncryptedResource)
  //console.log('stateChangedRenewalRecords', stateChangedRenewalRecords)
  //console.log('stateSelectedProperties', stateSelectedProperties)
  //console.log('stateUpdatedRenewalRecords', stateUpdatedRenewalRecords)
  //stateSelectedProperties.forEach(selectedProperty => {
  //console.log('stateSelectedProperties', services.renewalRecords.prepareRenewalRecordForUpdate(
  //    stateChangedRenewalRecords[selectedProperty],
  //    stateUpdatedRenewalRecords[selectedProperty]
  //))
  //});

  const submitDisabled =
    stateApiCallInprogress || stateSelectedProperties.length === 0;
  const [showSnackBar, setShowSnackbar] = useState(false);

  //console.log('submitDisabled', submitDisabled)

  const handleSubmittingChanges = useCallback(() => {
    const preparedRecordsChanges = [];

    let notValid = false;

    stateSelectedProperties.forEach(selectedProperty => {
      const preparedRecordChanges = services.renewalRecords.prepareRenewalRecordForUpdate(
        stateChangedRenewalRecords[selectedProperty],
        stateUpdatedRenewalRecords[selectedProperty]
      );

      if (preparedRecordChanges === null) {
        return;
      }

      // Some validation for messages
      const alx_fields = _.get(preparedRecordChanges, [
        'property',
        'alx_fields'
      ]);
      if (
        alx_fields &&
        Object.prototype.hasOwnProperty.call(
          alx_fields,
          'field_comments_further_information_new'
        )
      ) {
        if (
          Object.prototype.hasOwnProperty.call(
            alx_fields,
            'field_insurance_broker'
          ) &&
          // Is no name
          (!alx_fields.field_insurance_broker.trim().length ||
            // Only first or last name
            alx_fields.field_insurance_broker.trim().split(' ').length <= 1)
        ) {
          notValid = true;
          return;
        }

        if (
          Object.prototype.hasOwnProperty.call(
            alx_fields,
            'field_handler_comment_name'
          ) &&
          // Is no name
          (!alx_fields.field_handler_comment_name.trim().length ||
            // Only first or last name
            alx_fields.field_handler_comment_name.trim().split(' ').length <= 1)
        ) {
          notValid = true;
          return;
        }
      }

      preparedRecordsChanges.push(preparedRecordChanges);
    });

    if (notValid) {
      setShowSnackbar(true);
      return null;
    }

    if (
      preparedRecordsChanges.length === 0 &&
      request !== 'RENEWAL' &&
      requester !== 'HANDLER' &&
      requester !== 'ADDS'
    ) {
      return null;
    }

    return actionSubmitRecordChangesForEncryptedResource({
      encryptedResource: stateEncryptedResource,
      recordsToSubmit: stateSelectedProperties,
      preparedRecordsChanges
    });
  }, [
    stateEncryptedResource,
    stateChangedRenewalRecords,
    stateUpdatedRenewalRecords,
    stateSelectedProperties,
    actionSubmitRecordChangesForEncryptedResource
  ]);

  const style =
    stateSelectedProperties.length === 0
      ? {}
      : {
          color: '#ddd',
          background: `linear-gradient(135deg, ${config.styles.colors.quinary} 0%, rgba(255,255,255,1) 100%)`
        };

  return (
    <UIAnimateAutoHeight bIsOpen={!stateIsPropertyOpen}>
      <UIPaperFullWidth style={style}>
        <Box display="flex" padding="1em">
          <Typography
            align="center"
            component="div"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {stateSelectedProperties.length} record(s) selected
          </Typography>
          <Typography component="div" style={{ flexGrow: 1 }}></Typography>
          <Button
            disabled={submitDisabled}
            variant="contained"
            color="primary"
            onClick={handleSubmittingChanges}
          >
            {submitDisabled
              ? 'Use Checkboxes To Select Records For Submission'
              : 'Submit Selected Records'}
          </Button>
        </Box>
      </UIPaperFullWidth>
      <Box height="1em" />
      <Snackbar
        {...{
          handleClose: () => setShowSnackbar(false),
          isOpen: showSnackBar,
          message:
            'If you have added a message to a record, please ensure you have provided your first and last name.',
          type: 'warning'
        }}
      />
    </UIAnimateAutoHeight>
  );
};

export default RenewalActionsBarMultipleProperties;
