import _ from 'lodash';

const cached = {};

export const columnAndRecordConfigsFactory = path => {
  const key = JSON.stringify(path);

  if (cached[key] === undefined) {
    cached[key] = state => {
      return _.get(
        state['renewal']['dataTable']['columnAndRecordConfigs'],
        path
      );
    };
  }

  return cached[key];
};

export default columnAndRecordConfigsFactory;
