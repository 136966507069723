import React from 'react';
import AnimateHeight from 'react-animate-height';

export const UIAnimateAutoHeight = ({ duration, bIsOpen, children }) => {
  return (
    <AnimateHeight duration={duration || 500} height={bIsOpen ? 'auto' : 0}>
      {children}
    </AnimateHeight>
  );
};

export default UIAnimateAutoHeight;
