import React, { useCallback, useState } from 'react';
import { Box, TableBody, TableRow, TableHead } from '@material-ui/core';
import UIDataTableHeadCellRecordField from '../UI/DataTableHeadCellRecordField';
import UIDataTableHeadCellIsRecordEdited from '../UI/DataTableHeadCellIsRecordEdited';
import UIDataTableFullWidth from '../UI/DataTableFullWidth';
import UIPaperFullWidth from '../UI/PaperFullWidth';
import UIDataTableContainerFullWidthOverflow from '../UI/DataTableContainerFullWidthOverflow';
import UIDataTableAppBar from '../UI/DataTableAppBar';
import UIDataTablePagination from '../UI/DataTablePagination';
import UIAnimateAutoHeight from '../UI/AnimateAutoHeight';
import RenewalDataTableUnitsDataRow from './DataTableUnitsDataRow';

const RenewalDataTableUnits = ({
  actionChangeUnitRecordFieldValue,
  actionSetCurrentUnitsPage,
  actionSetNumOfUnitsToShowPerPage,
  stateCompositeUnitRecordsToShow,
  stateCurrentUnitsPage,
  stateIsPropertyOpen,
  stateNumOfTotalUnitRecords,
  stateNumOfUnitRecordsPerPage,
  stateOpenedPropertyIndex,
  stateUnitDataTableAlxColumns
}) => {
  const [bShowDataTable, setShowDataTable] = useState(true);

  const handleToggleShowDataTable = useCallback(() => {
    return setShowDataTable(!bShowDataTable);
  }, [bShowDataTable, setShowDataTable]);

  return (
    <UIAnimateAutoHeight bIsOpen={stateIsPropertyOpen}>
      <UIDataTableAppBar
        {...{
          bEnableFeatureHideDataTable: true,
          bShowDataTable: bShowDataTable,
          handleToggleShowDataTable,
          iconName: 'HomeIcon',
          numOfRecords: stateNumOfTotalUnitRecords,
          titleAppBar: 'UNITS',
          titleTooltip: 'Number of Units'
        }}
      />
      <React.Fragment>
        {bShowDataTable ? (
          <React.Fragment>
            <Box display="flex">
              <UIPaperFullWidth>
                <UIDataTablePagination
                  {...{
                    bShowPagination: stateIsPropertyOpen,
                    currentPage: stateCurrentUnitsPage,
                    handleSetCurrentPage: actionSetCurrentUnitsPage,
                    handleSetNumOfRecordsToShowPerPage: actionSetNumOfUnitsToShowPerPage,
                    numOfRecordsPerPage: stateNumOfUnitRecordsPerPage,
                    numOfRowsPerPageOptions: [5, 10, 25, 50],
                    numOfTotalRecords: stateNumOfTotalUnitRecords
                  }}
                />
                <UIDataTableContainerFullWidthOverflow>
                  <UIDataTableFullWidth>
                    <TableHead>
                      <TableRow>
                        <UIDataTableHeadCellIsRecordEdited />
                        {stateUnitDataTableAlxColumns.map(
                          (
                            {
                              columnInteractive,
                              columnLabel,
                              columnVisible,
                              columnWidth
                            },
                            index
                          ) => {
                            return (
                              <React.Fragment
                                key={`unlocked-column-th-${index}`}
                              >
                                {columnVisible ? (
                                  <UIDataTableHeadCellRecordField
                                    background={
                                      columnInteractive
                                        ? 'quinary'
                                        : 'secondary'
                                    }
                                    label={columnLabel}
                                    columnWidth={columnWidth}
                                    handleClick={() => {}}
                                  />
                                ) : null}
                              </React.Fragment>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {stateCompositeUnitRecordsToShow.map(
                        (compositeRecord, index) => {
                          return (
                            <RenewalDataTableUnitsDataRow
                              key={`table-row-${index}`}
                              {...{
                                actionChangeUnitRecordFieldValue,
                                compositeRecord,
                                propertyRecordIndex: stateOpenedPropertyIndex,
                                stateUnitDataTableAlxColumns
                              }}
                            />
                          );
                        }
                      )}
                    </TableBody>
                  </UIDataTableFullWidth>
                </UIDataTableContainerFullWidthOverflow>
                <UIDataTablePagination
                  {...{
                    bShowPagination: stateIsPropertyOpen,
                    currentPage: stateCurrentUnitsPage,
                    handleSetCurrentPage: actionSetCurrentUnitsPage,
                    handleSetNumOfRecordsToShowPerPage: actionSetNumOfUnitsToShowPerPage,
                    numOfRecordsPerPage: stateNumOfUnitRecordsPerPage,
                    numOfRowsPerPageOptions: [5, 10, 25, 50],
                    numOfTotalRecords: stateNumOfTotalUnitRecords
                  }}
                />
              </UIPaperFullWidth>
            </Box>
            <Box height="4em" />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </UIAnimateAutoHeight>
  );
};

export default RenewalDataTableUnits;
