import { SET_CURRENT_UNITS_PAGE } from '../constants';

export const actionSetCurrentUnitsPage = currentPage => {
  return async dispatch => {
    dispatch({
      type: SET_CURRENT_UNITS_PAGE,
      currentPage
    });
  };
};

export default actionSetCurrentUnitsPage;
