import React, { useCallback } from 'react';
import { Badge, Box, Toolbar, Tooltip, Typography } from '@material-ui/core';
import {
  ChromeReaderMode as ChromeReaderModeIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Home as HomeIcon,
  Menu as MenuIcon
} from '@material-ui/icons';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';
import UIAppBar from './AppBar';
import _ from 'lodash';

const BadgeIcons = {
  default: ChromeReaderModeIcon,
  ChromeReaderModeIcon,
  HomeIcon
};

export const UIDataTableAppBar = ({
  bEnableFeatureHideDataTable,
  bShowDataTable,
  handleToggleShowDataTable,
  iconName,
  numOfRecords,
  titleAppBar,
  titleTooltip,
  filterTooltip,
  stateIsPropertyOpen,
  openFilters,
  setOpenFilters
}) => {
  const BadgeIcon = BadgeIcons[iconName] || BadgeIcons['default'];

  const toggleShowDataTable = useCallback(() => {
    if (bEnableFeatureHideDataTable !== true) {
      return;
    }

    handleToggleShowDataTable();
  }, [bEnableFeatureHideDataTable, handleToggleShowDataTable]);

  return (
    <React.Fragment>
      <UIAppBar position="static" background="quaternary">
        <Toolbar>
          {bEnableFeatureHideDataTable ? (
            <UIFlexboxCenterPositioner
              onClick={toggleShowDataTable}
              style={{ cursor: 'pointer' }}
            >
              {bShowDataTable === true ? (
                <ExpandLessIcon fontSize="large" />
              ) : (
                <ExpandMoreIcon fontSize="large" />
              )}
            </UIFlexboxCenterPositioner>
          ) : null}
          <UIFlexboxCenterPositioner
            onClick={toggleShowDataTable}
            style={{ cursor: bEnableFeatureHideDataTable ? 'pointer' : 'auto' }}
          >
            <Typography component="h3">
              <strong>{_.toUpper(titleAppBar)}</strong>
            </Typography>
          </UIFlexboxCenterPositioner>

          <Typography component="div" style={{ flexGrow: 1 }}>
            {' '}
          </Typography>
          {filterTooltip && stateIsPropertyOpen === false ? (
            <Tooltip title={filterTooltip} placement="left">
              <Badge
                style={{ marginRight: '25px', cursor: 'pointer' }}
                color="secondary"
                onClick={() => setOpenFilters(!openFilters)}
              >
                <MenuIcon />
              </Badge>
            </Tooltip>
          ) : null}
          <Tooltip title={titleTooltip} placement="left">
            <Badge badgeContent={numOfRecords || 0} color="secondary">
              <BadgeIcon />
            </Badge>
          </Tooltip>
        </Toolbar>
      </UIAppBar>
      <Box height="1em" />
    </React.Fragment>
  );
};

export default UIDataTableAppBar;
