import {
  START_BACKEND_API_CALL,
  FINISH_BACKEND_API_CALL,
  SHOW_404,
} from '../constants';

const initialState = {
  callInProgress: true,
  show404: false,
};

export const apiReducer = (
  state = initialState,
  { type /*, encryptedResource*/ }
) => {
  switch (type) {
    case START_BACKEND_API_CALL:
      return {
        ...state,
        ...{ callInProgress: true },
      };
    case FINISH_BACKEND_API_CALL:
      return {
        ...state,
        ...{ callInProgress: false },
      };

    case SHOW_404:
      return {
        ...state,
        show404: true,
      };
    default:
      return state;
  }
};

export default apiReducer;
