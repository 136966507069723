import React from 'react';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import UIDataTableHeadCell from './DataTableHeadCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';

export const UIDataTableHeadCellIsRecordEdited = () => {
  return (
    <UIDataTableHeadCell width="xs" padding="none">
      <UIFlexboxCenterPositioner>
        <ErrorOutlineIcon />
      </UIFlexboxCenterPositioner>
    </UIDataTableHeadCell>
  );
};

export default UIDataTableHeadCellIsRecordEdited;
