import React, { useEffect } from 'react';

export default ToWrapComponent => {
  let RenewalBehaviour = props => {
    const {
      actionGetRecordsAndConfigsForEncryptedResource,
      location,
      ...other
    } = props;

    useEffect(() => {
      (async () => {
        await actionGetRecordsAndConfigsForEncryptedResource(
          location.pathname.replace('/renewal/', '')
        );
      })();

      return () => {};
    }, [location, actionGetRecordsAndConfigsForEncryptedResource]);

    return <ToWrapComponent {...other} />;
  };

  return RenewalBehaviour;
};
