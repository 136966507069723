import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import apiReducer from './reducers/api.reducer';
import renewalAccessReducer from './reducers/renewal/access.reducer';
import renewalDataTableColumnAndRecordConfigs from './reducers/renewal/dataTable/columnAndRecordConfigs.reducer';
import renewalDataTableClaimPaginationReducer from './reducers/renewal/dataTable/claim/pagination.reducer';
import renewalDataTablePropertyPaginationReducer from './reducers/renewal/dataTable/property/pagination.reducer';
import renewalDataTableUnitPaginationReducer from './reducers/renewal/dataTable/unit/pagination.reducer';
import renewalDataTablePropertyFilterReducer from './reducers/renewal/dataTable/property/filter.reducer';
import renewalRecordsReducer from './reducers/renewal/records.reducer';
import renewalUserReducer from './reducers/renewal/user.reducer';
import snackbarReducer from './reducers/snackbar.reducer';
import floatReducer from './reducers/float.reducer.js'

const combinedReducers = combineReducers({
  api: apiReducer,
  snackbar: snackbarReducer,
  float: floatReducer,
  renewal: combineReducers({
    access: renewalAccessReducer,
    user: renewalUserReducer,
    dataTable: combineReducers({
      columnAndRecordConfigs: renewalDataTableColumnAndRecordConfigs,
      property: combineReducers({
        pagination: renewalDataTablePropertyPaginationReducer,
        filter: renewalDataTablePropertyFilterReducer
      }),
      unit: combineReducers({
        pagination: renewalDataTableUnitPaginationReducer
      }),
      claim: combineReducers({
        pagination: renewalDataTableClaimPaginationReducer
      })
    }),
    records: renewalRecordsReducer
  })
});

const combinedDefaultState = {};

const store = createStore(
  combinedReducers,
  combinedDefaultState,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);

export default store;
