import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Toolbar, Typography } from '@material-ui/core';
import { useTransition, animated } from 'react-spring';

import config from '../../config';
import { FIELD_GROUP_ALX_FIELDS } from '../../consts';
import { Input, TextFieldFullWidth, PaperFullWidth, AppBar } from '../UI';

const RenewalMessagesTable = ({
  actionChangePropertyRecordFieldValue,
  stateChangedRenewalRecords,
  stateOpenedPropertyIndex,
  stateShowNewCommentBox,
  stateShowOldMessages,
  stateUpdatedRenewalRecords,
  requesterType,
  requesterName
}) => {
  const propertyUpdatedRecord = _.get(stateUpdatedRenewalRecords, [
    stateOpenedPropertyIndex,
    'property'
  ]);
  const propertyChangedRecord = _.get(stateChangedRenewalRecords, [
    stateOpenedPropertyIndex,
    'property'
  ]);
  const fieldValue =
    _.get(propertyChangedRecord, [
      FIELD_GROUP_ALX_FIELDS,
      'field_comments_further_information_new'
    ]) ||
    _.get(propertyUpdatedRecord, [
      FIELD_GROUP_ALX_FIELDS,
      'field_comments_further_information_new'
    ]) ||
    '';
  const oldMessages = _.get(
    propertyUpdatedRecord,
    [FIELD_GROUP_ALX_FIELDS, 'field_comments_further_information_history'],
    null
  );
  const oldMessagesArray = (typeof oldMessages === 'string' ? oldMessages : '')
    .split(config.messages.historyDelimiter)
    .map(message => message.trim())
    .filter(message => message !== '')
    .reverse();

  const [message, setMessage] = useState(fieldValue);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [timer, setTimer] = useState(null);

  /**
   * @description
   */
  const handleFieldChange = useCallback(
    (key, newValue) => {
      const allValues = { message, firstname, lastname };
      allValues[key] = newValue;

      actionChangePropertyRecordFieldValue({
        propertyRecordIndex: stateOpenedPropertyIndex,
        fieldGroup: FIELD_GROUP_ALX_FIELDS,
        fieldName: 'field_comments_further_information_new',
        fieldValue: allValues['message']
      });

      actionChangePropertyRecordFieldValue({
        propertyRecordIndex: stateOpenedPropertyIndex,
        fieldGroup: FIELD_GROUP_ALX_FIELDS,
        fieldName:
          requesterType === 'HANDLER' || requesterType === 'ADDS'
            ? 'field_handler_comment_name'
            : 'field_insurance_broker',
        fieldValue: `${allValues['firstname']} ${allValues['lastname']}`
      });
    },
    [
      stateOpenedPropertyIndex,
      actionChangePropertyRecordFieldValue,
      message,
      firstname,
      lastname,
      requesterType
    ]
  );

  const timeoutWrapper = (key, newValue) => () =>
    handleFieldChange(key, newValue);

  useEffect(() => {
    // set the name if a handler
    if (requesterType === 'HANDLER' || requesterType === 'ADDS') {
      const name = (requesterName && requesterName.trim().split(' ')) || '';

      // If only one word
      if (name.length) setFirstname(name[0]);

      // If  more than one word ad the last names
      if (name.length > 1) setLastname(name[1]);
    }

    setMessage(fieldValue);
  }, [fieldValue, requesterName, requesterType]);

  const messageTransition = {
    from: { maxHeight: '0px', overflowY: 'hidden' },
    enter: { maxHeight: '500px', overflowY: 'visible' },
    leave: { maxHeight: '0px', overflowY: 'hidden' }
  };
  const newMessageTransactions = useTransition(
    stateShowNewCommentBox,
    null,
    messageTransition
  );
  const oldMessagesTransactions = useTransition(
    stateShowOldMessages,
    null,
    messageTransition
  );

  // /**
  //  * Handles the message input
  //  * @param {object} event
  //  */
  // const handleMessageInput = event => {
  //     clearTimeout(timer);
  //     setValue(event.target.value);
  //     setTimer(setTimeout(timeoutWrapper(event.target.value), 300));
  // }

  /**
   *
   * @param {object} event
   */
  const handleUserInput = (event, key) => {
    const { value } = event.target;

    clearTimeout(timer);

    switch (key) {
      case 'firstname':
        setFirstname(value);
        break;
      case 'lastname':
        setLastname(value);
        break;
      default:
        setMessage(value);
        break;
    }

    setTimer(setTimeout(timeoutWrapper(key, value), 300));
  };

  return (
    <div className="message-container">
      {newMessageTransactions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <AppBar position="static" background="tertiary">
                <Toolbar>
                  <Typography component="h3">New Message</Typography>
                </Toolbar>
              </AppBar>
              <Box height="1em" />
              <PaperFullWidth>
                <Box padding="1em">
                  <TextFieldFullWidth
                    multiline
                    value={message}
                    onChange={e => handleUserInput(e, 'message')}
                    variant="outlined"
                    rows="8"
                  />
                  <div style={{ marginTop: '10px' }}>
                    <Input
                      type="text"
                      placeholder="Firstname"
                      value={firstname}
                      onChange={e => handleUserInput(e, 'firstname')}
                      width="calc(50% - 5px)"
                    />
                    <Input
                      type="text"
                      placeholder="Lastname"
                      value={lastname}
                      onChange={e => handleUserInput(e, 'lastname')}
                      float="right"
                      width="calc(50% - 5px)"
                    />
                  </div>
                </Box>
              </PaperFullWidth>
              <Box height="1em" />
            </animated.div>
          )
      )}
      {oldMessagesTransactions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <AppBar position="static" background="tertiary">
                <Toolbar>
                  <Typography component="h3">Message History</Typography>
                </Toolbar>
              </AppBar>
              <Box height="1em" />
              {oldMessagesArray.map((message, index) => {
                return (
                  <React.Fragment key={`message-${index}`}>
                    <PaperFullWidth>
                      <Box padding="1em">
                        <Typography component="p">{message}</Typography>
                      </Box>
                    </PaperFullWidth>
                    <Box height="1em" />
                  </React.Fragment>
                );
              })}
            </animated.div>
          )
      )}
    </div>
  );
};

export default RenewalMessagesTable;
