export const paginationHelper = ({
  currentPage,
  numOfRecordsPerPage,
  totalNumOfRecords
}) => {
  const startAt = currentPage * numOfRecordsPerPage;
  let endAt = startAt + numOfRecordsPerPage;
  endAt = totalNumOfRecords <= endAt ? totalNumOfRecords : endAt;

  return { startAt, endAt };
};

export default paginationHelper;
