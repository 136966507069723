import {
  SET_RENEWAL_CONFIGS,
  RESET_RENEWAL_CONFIGS,
  SET_RENEWAL_RECORDS,
  RESET_RENEWAL_RECORDS,
  SET_ENCRYPTED_RESOURCE,
  RESET_ENCRYPTED_RESOURCE,
  RESET_PROPERTIES_DATATABLE,
  RESET_UNITS_DATATABLE,
  START_BACKEND_API_CALL,
  FINISH_BACKEND_API_CALL,
  SET_USER,
  RESET_USER,
  OPEN_SUCCESS_SNACKBAR,
  OPEN_ERROR_SNACKBAR,
  SHOW_404
} from '../constants';
import services from '../../services';

export const actionGetRecordsAndConfigsForEncryptedResource = (
  encryptedResource
) => {
  return async (dispatch) => {
    dispatch({
      type: START_BACKEND_API_CALL,
    });
    dispatch({
      type: RESET_USER,
    });
    dispatch({
      type: RESET_PROPERTIES_DATATABLE,
    });
    dispatch({
      type: RESET_UNITS_DATATABLE,
    });
    dispatch({
      type: RESET_ENCRYPTED_RESOURCE,
    });
    dispatch({
      type: RESET_RENEWAL_CONFIGS,
    });
    dispatch({
      type: RESET_RENEWAL_RECORDS,
    });

    let [
      configsResponse,
      personalInformationResponse,
      recordsResponse,
    ] = await Promise.all([
      services.api.getConfigsForEncryptedResource(encryptedResource),
      services.api.getPersonalInformationForEncryptedResource(
        encryptedResource
      ),
      services.api.getRecordsForEncryptedResource(encryptedResource),
    ]);

    // If user does not have access to range then 404
    if (
      !configsResponse.success ||
      !personalInformationResponse.success ||
      !recordsResponse.success
    ) {
      if (
        configsResponse.statusCode === 404 ||
        personalInformationResponse.statusCode === 404 ||
        recordsResponse.statusCode === 404
      ) {
        dispatch({
          type: SHOW_404,
        });
        return;
      }
    }

    if (!configsResponse.success) {
      dispatch({
        type: OPEN_ERROR_SNACKBAR,
        message: configsResponse.errors[0].message,
      });
      return;
    }

    if (!personalInformationResponse.success) {
      dispatch({
        type: OPEN_ERROR_SNACKBAR,
        message: personalInformationResponse.errors[0].message,
      });
      return;
    }

    if (!recordsResponse.success) {
      dispatch({
        type: OPEN_ERROR_SNACKBAR,
        message: recordsResponse.errors[0].message,
      });
      return;
    }

    dispatch({
      type: SET_ENCRYPTED_RESOURCE,
      encryptedResource,
    });
    dispatch({
      type: SET_USER,
      ...personalInformationResponse.payload,
    });
    dispatch({
      type: SET_RENEWAL_CONFIGS,
      renewalConfigs: configsResponse.payload,
    });
    dispatch({
      type: SET_RENEWAL_RECORDS,
      renewalRecords: recordsResponse.payload.record_packs,
    });
    dispatch({
      type: FINISH_BACKEND_API_CALL,
    });
    dispatch({
      type: OPEN_SUCCESS_SNACKBAR,
      message: 'Records successfully loaded!',
    });
  };
};

export default actionGetRecordsAndConfigsForEncryptedResource;
