import React from 'react';
import { TablePagination } from '@material-ui/core';

export const UIDataTablePagination = ({
  bShowPagination,
  currentPage,
  handleSetCurrentPage,
  handleSetNumOfRecordsToShowPerPage,
  numOfRecordsPerPage,
  numOfRowsPerPageOptions,
  numOfTotalRecords
}) => {
  return (
    <React.Fragment>
      {bShowPagination ? (
        <TablePagination
          rowsPerPageOptions={numOfRowsPerPageOptions}
          component="div"
          count={numOfTotalRecords}
          rowsPerPage={numOfRecordsPerPage}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onChangePage={(e, newPage) => {
            handleSetCurrentPage(newPage);
          }}
          onChangeRowsPerPage={e => {
            handleSetNumOfRecordsToShowPerPage(e.target.value);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default UIDataTablePagination;
