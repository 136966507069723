import {
    RESET_DATATABLES_FILTER,
    REMOVE_RENEWAL_RECORDS,
    SET_CURRENT_PROPERTIES_PAGE,
    SET_OPENED_PROPERTY,
    REMOVE_PROPERTIES_FROM_SELECTED,
    START_BACKEND_API_CALL,
    FINISH_BACKEND_API_CALL,
    OPEN_SUCCESS_SNACKBAR,
    OPEN_ERROR_SNACKBAR
} from '../constants';

import services from '../../services';

export const actionSubmitRecordChangesForEncryptedResource = ({
    encryptedResource,
    recordsToSubmit,
    preparedRecordsChanges
}) => {
    return async dispatch => {
        dispatch({
            type: START_BACKEND_API_CALL
        });

        const changesResponse = await services.api.submitRecordChangesForEncryptedResource(
            encryptedResource,
            preparedRecordsChanges
        );

        if (!changesResponse.success) {
            dispatch({
                type: FINISH_BACKEND_API_CALL
            });
            dispatch({
                type: OPEN_ERROR_SNACKBAR,
                message: changesResponse.errors[0]
            });
            return;
        }

        dispatch({
            type: RESET_DATATABLES_FILTER
        });
        dispatch({
            type: SET_CURRENT_PROPERTIES_PAGE,
            currentPage: 0
        });
        dispatch({
            type: SET_OPENED_PROPERTY,
            openedPropertyIndex: null
        });
        dispatch({
            type: REMOVE_PROPERTIES_FROM_SELECTED,
            propertiesToRemoveFromSelected: recordsToSubmit
        });
        dispatch({
            type: REMOVE_RENEWAL_RECORDS,
            renewalRecordsToRemove: recordsToSubmit
        });
        dispatch({
            type: FINISH_BACKEND_API_CALL
        });
        dispatch({
            type: OPEN_SUCCESS_SNACKBAR,
            message: 'Records successfully changed!'
        });
    };
};

export default actionSubmitRecordChangesForEncryptedResource;
