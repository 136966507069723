import { SET_FLOAT } from '../constants'

const floatReducer = (state = 0, action) => {
    switch(action.type) {
        case SET_FLOAT: 
            return action.payload
        default: 
            return state
    }
}

export default floatReducer