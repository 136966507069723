import React, { useCallback } from 'react';
import { TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
//import config from 'config';
import UIDataTableBodyCellCheckbox from '../UI/DataTableBodyCellCheckbox';
import UIDataTableBodyCellOpenRecord from '../UI/DataTableBodyCellOpenRecord';
import UIDataTableBodyCellIsRecordEdited from '../UI/DataTableBodyCellIsRecordEdited';
import UIDataTableBodyCellHasMessage from '../UI/DataTableBodyCellHasMessage';
import UIDataTableBodyCellRecordField from '../UI/DataTableBodyCellRecordField';
//import UIDataTableBodyCellAssetDoc from '../UI/DataTableBodyCellAssetDoc';
import {
  FIELD_GROUP_COORDINATOR_FIELDS,
  FIELD_GROUP_ALX_FIELDS
} from '../../consts';

const RenewalDataTablePropertiesDataRow = ({
  actionChangePropertyRecordFieldValue,
  actionResetPropertyRecordAndItsUnitRecords,
  actionSetOpenedProperty,
  actionToggleOpenedProperty,
  actionToggleSelectedProperty,
  compositeRecord,
  stateChangedRecordIndexes,
  stateEditModesBasedOnState,
  stateIsPropertyOpen,
  statePropertyDataTableAlxColumns,
  stateSelectedProperties
  //role
}) => {
  const propertyRecordIndex = compositeRecord['recordIndex'];
  //const request = useSelector(state => state.renewal.user.request);
  const requester = useSelector(state => state.renewal.user.requester);
  const bIsRecordSelectable = stateChangedRecordIndexes.includes(
    propertyRecordIndex
  );
  const bIsRecordSelected = stateSelectedProperties.includes(
    propertyRecordIndex
  );
  const action = true;

  const handleToggleOpenedProperty = useCallback(
    trueCondition => {
      if (!trueCondition) {
        return;
      }
      return actionToggleOpenedProperty(propertyRecordIndex);
    },
    [actionToggleOpenedProperty, propertyRecordIndex]
  );

  const handleToggleSelectedProperty = useCallback(() => {
    return actionToggleSelectedProperty(propertyRecordIndex);
  }, [actionToggleSelectedProperty, propertyRecordIndex]);

  const handleAlxFieldChange = useCallback(
    fieldName => {
      return newValue => {
        actionChangePropertyRecordFieldValue({
          propertyRecordIndex,
          fieldGroup: FIELD_GROUP_ALX_FIELDS,
          fieldName: fieldName,
          fieldValue: newValue
        });
      };
    },
    [actionChangePropertyRecordFieldValue, propertyRecordIndex]
  );

  const handleNextStateFieldChange = useCallback(
    newValue => {
      actionSetOpenedProperty(
        stateEditModesBasedOnState[newValue] ? propertyRecordIndex : null
      );
      actionResetPropertyRecordAndItsUnitRecords({
        propertyRecordIndex
      });
      actionChangePropertyRecordFieldValue({
        propertyRecordIndex,
        fieldGroup: FIELD_GROUP_COORDINATOR_FIELDS,
        fieldName: 'coordinator_field_next_state',
        fieldValue: newValue
      });
    },
    [
      actionChangePropertyRecordFieldValue,
      actionResetPropertyRecordAndItsUnitRecords,
      actionSetOpenedProperty,
      propertyRecordIndex,
      stateEditModesBasedOnState
    ]
  );

  const handleCurrentStateFieldChange = useCallback(
    newValue => {
      actionChangePropertyRecordFieldValue({
        propertyRecordIndex,
        fieldGroup: FIELD_GROUP_COORDINATOR_FIELDS,
        fieldName: 'coordinator_field_current_state',
        fieldValue: newValue
      });
    },
    [actionChangePropertyRecordFieldValue, propertyRecordIndex]
  );

  // console.log('compositeRecord', request, requester) // @TODO THERE IS THE CODE MISSING - CHECK THE FIELD WHICH IS RESPONSIBLE FOR COLOR!!!!
  // console.log('action', ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
  //   'coordinator_field_current_state'
  //   ])

  const isCheckboxVisible = () => {
    const record =
      compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
        'coordinator_field_next_state'
      ];
    const currentState =
      compositeRecord.coordinator_fields.coordinator_field_current_state;
    const noActionOption =
      record.fieldSelects &&
      record.fieldSelects.find(item => item[1] === 'No action');
    const noActionValue = noActionOption && noActionOption[0];
    const withHandlerInsurerAccount =
      currentState.fieldLabel === 'With handler for agreed scheme updates' &&
      requester === 'INSURER';

    //console.log(compositeRecord.coordinator_fields.coordinator_field_current_state);

    return (
      (record.fieldValue !== noActionValue &&
        record.fieldSelects &&
        record.fieldSelects.length > 0) ||
      (record.fieldValue === 0 && !withHandlerInsurerAccount)
    );
  };
  // style={{
  //   backgroundColor: compositeRecord['doesRecordDifferFromOriginal'] ||
  //   compositeRecord['coordinator_fields']['coordinator_field_current_state']['fieldLabel'] === 'With Insurer for review'
  //     ? config.styles.colors.backgroundHighlight
  //     : null
  // }}
  return (
    <TableRow>
      <UIDataTableBodyCellOpenRecord
        isOpen={stateIsPropertyOpen}
        handleClick={() => handleToggleOpenedProperty(true)}
      />
      {stateIsPropertyOpen ? null : (
        <UIDataTableBodyCellCheckbox
          isVisible={isCheckboxVisible()}
          isChecked={bIsRecordSelected}
          handleChange={handleToggleSelectedProperty}
        />
      )}
      <UIDataTableBodyCellHasMessage
        {...{
          showIcon: compositeRecord['hasMessages'],
          showWarning: false /* TODO FIX*/,
          handleClick: () => {
            handleToggleOpenedProperty(compositeRecord['hasMessages']);
          }
        }}
      />
      <UIDataTableBodyCellIsRecordEdited
        {...{
          showIcon: compositeRecord['doesRecordDifferFromOriginal'],
          handleClick: () => {
            handleToggleOpenedProperty(
              compositeRecord['doesRecordDifferFromOriginal']
            );
          }
        }}
      />
      <UIDataTableBodyCellRecordField
        {...{
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_current_state'
          ],
          handleFieldChange: handleCurrentStateFieldChange
        }}
      />

      <UIDataTableBodyCellRecordField
        {...{
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_next_state'
          ],
          handleFieldChange: handleNextStateFieldChange,
          bIsRecordSelectable,
          action
        }}
      />

      <UIDataTableBodyCellRecordField
        {...{
          columnWidth: 'xs',
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_number_of_units'
          ],
          handleFieldChange: () => {}
        }}
      />

      <UIDataTableBodyCellRecordField
        {...{
          columnWidth: 'xs',
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_number_of_claims'
          ],
          handleFieldChange: () => {}
        }}
      />

      <UIDataTableBodyCellRecordField
        {...{
          columnWidth: 'xs',
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
            'coordinator_field_number_of_claims_12_mos'
          ],
          handleFieldChange: () => {}
        }}
      />

      {/* <UIDataTableBodyCellRecordField
        {...{
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
          'coordinator_field_peril'
          ],
          handleFieldChange: handleCurrentStateFieldChange
        }}
      />

      <UIDataTableBodyCellRecordField
        {...{
          ...compositeRecord[FIELD_GROUP_COORDINATOR_FIELDS][
          'coordinator_field_occupancy'
          ],
          handleFieldChange: handleCurrentStateFieldChange
        }}
      /> */}

      {statePropertyDataTableAlxColumns.map(
        ({ fieldName, columnVisible, columnWidth }, index) => {
          const fieldConfig =
            compositeRecord[FIELD_GROUP_ALX_FIELDS][fieldName];

          return (
            <React.Fragment key={`unlocked-column-td-cell-${index}`}>
              {columnVisible ? (
                <UIDataTableBodyCellRecordField
                  {...{
                    columnWidth,
                    ...fieldConfig,
                    handleFieldChange: handleAlxFieldChange(
                      fieldConfig['fieldName']
                    )
                  }}
                />
              ) : null}
            </React.Fragment>
          );
        }
      )}
    </TableRow>
  );
};

export default RenewalDataTablePropertiesDataRow;
