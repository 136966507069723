import React from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import UIDataTableHeadCell from './DataTableHeadCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';

const StyledCheckbox = styled(Checkbox)`
  color: #fff !important;
`;

export const UIDataTableHeadCellCheckbox = ({
  isChecked,
  isIndeterminate,
  isVisible,
  onClick
}) => {
  return (
    <UIDataTableHeadCell width="xs" padding="none">
      <UIFlexboxCenterPositioner>
        {isVisible === true ? (
          <StyledCheckbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onClick={onClick}
          />
        ) : null}
      </UIFlexboxCenterPositioner>
    </UIDataTableHeadCell>
  );
};

export default UIDataTableHeadCellCheckbox;
