import axios from 'axios';
import config from '../config';
//import records from './records';

class Records {
  async getRecordsForEncryptedResource(encryptedResource) {
    //return records;
    const { data } = await axios({
      method: 'POST',
      url: config.api.urls.fetchRecords,
      responseType: 'json',
      data: {
        encrypted_resource: encryptedResource
      }
    });

    return data;
  }

  async getPersonalInformationForEncryptedResource(encryptedResource) {
    const { data } = await axios({
      method: 'POST',
      url: config.api.urls.fetchPersonalInformation,
      responseType: 'json',
      data: {
        encrypted_resource: encryptedResource
      }
    });

    return data;
  }

  async getConfigsForEncryptedResource(encryptedResource) {
    const { data } = await axios({
      method: 'POST',
      url: config.api.urls.fetchConfig,
      responseType: 'json',
      data: {
        encrypted_resource: encryptedResource
      }
    });

    return data;
  }

  async submitRecordChangesForEncryptedResource(encryptedResource, changes) {
    const { data } = await axios({
      method: 'POST',
      url: config.api.urls.saveChangedRecords,
      responseType: 'json',
      data: {
        encrypted_resource: encryptedResource,
        changes
      }
    });

    return data;
  }

  async updateTarget(encryptedResource, id, updates) {

    const currBaseURL = window.location.origin

    const baseURL = (currBaseURL.includes("dev-") || currBaseURL.includes("localhost")) ? 'dev-locktonreac.autologyx.com' : 'locktonreac.autologyx.com'

    const { data } = await axios({
      method: 'POST',
      url: config.api.urls.update,
      responseType: 'json',
      data: {
        encrypted_resource: encryptedResource,
        id,
        baseURL,
        updates
      }
    });

    return data;
  }
}

export default new Records();
