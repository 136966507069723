import { RESET_RENEWAL_RECORD } from '../constants';

export const actionResetPropertyRecordAndItsUnitRecords = ({
  propertyRecordIndex
}) => {
  return async dispatch => {
    dispatch({
      type: RESET_RENEWAL_RECORD,
      propertyRecordIndex
    });
  };
};

export default actionResetPropertyRecordAndItsUnitRecords;
