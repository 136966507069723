import _ from 'lodash';

export const filterRecords = ({
  dataTableFilter,
  fieldCategory,
  recordsChanged,
  recordsUpdated
}) => {

  const filters = dataTableFilter[fieldCategory];
  const hasFilters = 0 < _.size(filters);

  if (!hasFilters) {
    return recordsChanged.map(changedRecord => {
      return changedRecord[fieldCategory]['meta']['index'];
    });
  }

  const filteredRecordIndexes = [];

  _.forEach(recordsChanged, (changedRecord, i) => {
    const recordIndex = changedRecord[fieldCategory]['meta']['index'];
    let bPassesFilter = true;

    const textFilters = (fieldName) => {
      const fieldValue = recordsUpdated[i][fieldCategory].alx_fields[fieldName].toLowerCase();
      const filterValue = filters.alx_fields[fieldName]

      if (filterValue && !fieldValue.includes(filterValue.toLowerCase())) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "coordinator_field_number_of_claims") && filters.coordinator_fields.coordinator_field_number_of_claims.length) {
      const fieldValue = recordsUpdated[i][fieldCategory].coordinator_fields.coordinator_field_number_of_claims;
      const filterValue = filters.coordinator_fields.coordinator_field_number_of_claims

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue)) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "coordinator_field_current_state") && filters.coordinator_fields.coordinator_field_current_state.length) {
      const fieldValue = recordsUpdated[i][fieldCategory].alx_fields.field_renewal_status;
      const filterValue = filters.coordinator_fields.coordinator_field_current_state

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue)) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "coordinator_field_number_of_units") && filters.coordinator_fields.coordinator_field_number_of_units.length) {
      const fieldValue = recordsUpdated[i][fieldCategory].coordinator_fields.coordinator_field_number_of_units;
      const filterValue = filters.coordinator_fields.coordinator_field_number_of_units

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue)) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "coordinator_field_number_of_claims_12_mos") && filters.coordinator_fields.coordinator_field_number_of_claims_12_mos.length) {
      const fieldValue = recordsUpdated[i][fieldCategory].coordinator_fields.coordinator_field_number_of_claims_12_mos;
      const filterValue = filters.coordinator_fields.coordinator_field_number_of_claims_12_mos

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue)) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "field_c_peril") && filters.coordinator_fields.field_c_peril.length) {
      const claims = recordsUpdated[i]["claims"];
      const filterValue = filters.coordinator_fields.field_c_peril

      let fieldValue = []
      for (let i = 0; i < claims.length; i++) {
        for (let x = 0; x < filterValue.length; x++) {
          if (claims[i]["alx_fields"]["field_c_peril"] === filterValue[x]) {
            fieldValue.push(claims[i]["alx_fields"]["field_c_peril"])
          }
        }
      }

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue[0])) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "field_u_occupied_vacant") && filters.coordinator_fields.field_u_occupied_vacant.length) {
      const units = recordsUpdated[i]["units"]
      const filterValue = filters.coordinator_fields.field_u_occupied_vacant

      let fieldValue = []
      for (let i = 0; i < units.length; i++) {
        for (let x = 0; x < filterValue.length; x++) {
          if (units[i]["alx_fields"]["field_u_occupied_vacant"] === filterValue[x]) {
            fieldValue.push(units[i]["alx_fields"]["field_u_occupied_vacant"])
          }
        }
      }

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue[0])) {
        bPassesFilter = false;
      }
    }

    if (filters.coordinator_fields && Object.prototype.hasOwnProperty.call(filters.coordinator_fields, "field_u_occupancy") && filters.coordinator_fields.field_u_occupancy.length) {
      const units = recordsUpdated[i]["units"]
      const filterValue = filters.coordinator_fields.field_u_occupancy

      let fieldValue = []
      for (let i = 0; i < units.length; i++) {
        for (let x = 0; x < filterValue.length; x++) {
          if (units[i]["alx_fields"]["field_u_occupancy"] === filterValue[x]) {
            fieldValue.push(units[i]["alx_fields"]["field_u_occupancy"])
          }
        }
      }

      if (filterValue.length === 0) {
        bPassesFilter = true
      }

      if (filterValue.length && !filterValue.includes(fieldValue[0])) {
        bPassesFilter = false;
      }
    }

    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_handler_name") && filters.alx_fields.field_handler_name.length) {
      textFilters("field_handler_name")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_client_name") && filters.alx_fields.field_client_name.length) {
      textFilters("field_client_name")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_p_property_address") && filters.alx_fields.field_p_property_address.length) {
      textFilters("field_p_property_address")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_p_property_post_code") && filters.alx_fields.field_p_property_post_code.length) {
      textFilters("field_p_property_post_code")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_p_commercial_residential") && filters.alx_fields.field_p_commercial_residential.length) {
      textFilters("field_p_commercial_residential")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_p_insured_name") && filters.alx_fields.field_p_insured_name.length) {
      textFilters("field_p_insured_name")
    }
    if (filters.alx_fields && Object.prototype.hasOwnProperty.call(filters.alx_fields, "field_property_id") && filters.alx_fields.field_property_id.length) {
      textFilters("field_property_id")
    }
    if (!bPassesFilter) {
      return;
    }

    filteredRecordIndexes.push(recordIndex);
  });

  return filteredRecordIndexes;
};

export default filterRecords;
