import React from 'react';
import { Notes as ChatBubbleIcon } from '@material-ui/icons';
import UIDataTableHeadCell from './DataTableHeadCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';

export const UIDataTableHeadCellHasMessage = () => {
  return (
    <UIDataTableHeadCell width="xs" padding="none">
      <UIFlexboxCenterPositioner>
        <ChatBubbleIcon fontSize="small" />
      </UIFlexboxCenterPositioner>
    </UIDataTableHeadCell>
  );
};

export default UIDataTableHeadCellHasMessage;
