import React, { useEffect, useState } from 'react'
import { Box, Toolbar, Typography } from '@material-ui/core';
import { animated } from 'react-spring';
import { UIAppBar as AppBar } from '../UI/AppBar';
import { UIPaperFullWidth as PaperFullWidth } from '../UI/PaperFullWidth';
import DataTableBodyCellAssetDoc from '../UI/DataTableBodyCellAssetDoc';
import _ from 'lodash'

const RenewalAssetDocumentStatus = ({
    stateOpenedPropertyIndex,
    stateUpdatedRenewalRecords
}) => {

    const [properyID, setPropertyID] = useState(null)

    //useEffect(() => {
        //console.log("🚀 ~ file: AssetDocumentStatus.jsx ~ line 15 ~ stateOpenedPropertyIndex", stateOpenedPropertyIndex)
        //console.log("🚀 ~ file: AssetDocumentStatus.jsx ~ line 15 ~ stateUpdatedRenewalRecords", stateUpdatedRenewalRecords)
    //}, [stateOpenedPropertyIndex, stateUpdatedRenewalRecords])

    useEffect(() => {
        //console.log("🚀 ~ file: AssetDocumentStatus.jsx ~ line 22 ~ properyID", properyID)
        if(stateOpenedPropertyIndex || stateOpenedPropertyIndex === 0) {
            setPropertyID(_.get(stateUpdatedRenewalRecords, [stateOpenedPropertyIndex, 'property', 'alx_fields', 'field_property_id' ], null))
        }
    },[stateOpenedPropertyIndex, properyID] )

    if ((!stateOpenedPropertyIndex && stateOpenedPropertyIndex !== 0)  || !properyID) {
        return null
    }

    return (
        <div className='asset-document-container'>
            <animated.div>
                <AppBar
                    position="static"
                    background="tertiary"
                >
                    <Toolbar>
                        <Typography component="h3">
                            Document Status & Options
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box height="1em" />
                <PaperFullWidth>
                    <Box padding="1em">
                        <DataTableBodyCellAssetDoc 
                            propertyID={properyID}
                        />
                    </Box>
                </PaperFullWidth>
            </animated.div>
        </div>
    )

}

export default RenewalAssetDocumentStatus