import {
  RESET_UNITS_DATATABLE,
  SET_CURRENT_UNITS_PAGE,
  SET_NUMBER_OF_UNITS_TO_SHOW_PER_PAGE
} from '../../../../constants';

const initialState = {
  numOfRecordsPerPage: 25,
  currentPage: 0
};

export const renewalUnitDataTableReducer = (
  state = initialState,
  { type, currentPage, numOfRecordsPerPage }
) => {
  switch (type) {
    case RESET_UNITS_DATATABLE:
      return {
        ...initialState
      };

    case SET_CURRENT_UNITS_PAGE:
      return {
        ...state,
        ...{
          currentPage
        }
      };

    case SET_NUMBER_OF_UNITS_TO_SHOW_PER_PAGE:
      return {
        ...state,
        ...{
          numOfRecordsPerPage
        }
      };

    default:
      return state;
  }
};

export default renewalUnitDataTableReducer;
