const cached = {};

export const filterFactory = table => {
  const key = table;

  if (cached[key] === undefined) {
    cached[key] = state => {
      return state['renewal']['dataTable'][table]['filter'];
    };
  }

  return cached[key];
};

export default filterFactory;
