import React from 'react';
import { Badge, Tooltip } from '@material-ui/core';
import { Notes as NotesIcon } from '@material-ui/icons';
import UIDataTableBodyCell from './DataTableBodyCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';

export const UIDataTableBodyCellHasMessage = ({
  showIcon,
  showWarning,
  handleClick
}) => {
  return (
    <UIDataTableBodyCell width="xs" padding="none">
      {showIcon ? (
        <UIFlexboxCenterPositioner
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          {showWarning ? (
            <Badge badgeContent="!" color="primary">
              <NotesIcon fontSize="small" />
            </Badge>
          ) : (
            <Tooltip title="There are comments for this property.">
              <NotesIcon fontSize="small" />
            </Tooltip>
          )}
        </UIFlexboxCenterPositioner>
      ) : null}
    </UIDataTableBodyCell>
  );
};

export default UIDataTableBodyCellHasMessage;
