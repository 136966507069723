import { SET_USER, RESET_USER } from '../../constants';

const initialState = {
  id: null,
  name: null,
  email: null,
  requester: null,
  request: null,
};

export const renewalUserReducer = (
  state = initialState,
  { type, id, name, email, requester, request }
) => {
  switch (type) {
    case RESET_USER:
      return { ...initialState };

    case SET_USER:
      return {
        ...{
          id,
          name,
          email,
          requester,
          request
        }
      };

    default:
      return state;
  }
};

export default renewalUserReducer;
