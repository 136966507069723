import React from 'react';
import styled from 'styled-components';
import config from '../../config';

const StyledDiv = styled.div`
  border: ${({ type = 'info' }) => {
    return `1px solid ${config.styles.colors.bootstrapAlert[type].border}`;
  }};
  background-color: ${({ type }) => {
    return `${config.styles.colors.bootstrapAlert[type].background}`;
  }};
  color: ${({ type }) => {
    return `${config.styles.colors.bootstrapAlert[type].text}`;
  }};
  padding: 0.5em;
`;

export const UIBootstrapLikeAlert = ({ type, style, children }) => {
  return (
    <StyledDiv type={type} style={style}>
      {children}
    </StyledDiv>
  );
};

export default UIBootstrapLikeAlert;
