import _ from 'lodash';
import { createSelector } from 'reselect';
import { FIELD_GROUP_COORDINATOR_FIELDS } from '../../consts';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';

export const selectorPropertyDataTableCoordinatorColumns = createSelector(
  [
    columnAndRecordConfigsSelectorFactory([
      'propertyColumnConfigsBasedOnState',
      FIELD_GROUP_COORDINATOR_FIELDS
    ]),
    columnAndRecordConfigsSelectorFactory('fieldGroupsNumericalValues')
  ],
  (coordinatorColumnConfigsBasedOnState, fieldGroupsNumericalValues) => {
    const fieldGroupsStringValues = _.invert(fieldGroupsNumericalValues);
    const columns = {};

    _.map(coordinatorColumnConfigsBasedOnState, coordinatorColumnConfig => {
      const {
        column_label,
        field_group,
        field_name,
        has_filter,
        interactive,
        visible,
        width
      } = coordinatorColumnConfig;

      columns[field_name] = {
        columnHasFilter: has_filter,
        columnInteractive: interactive,
        columnLabel: column_label,
        columnVisible: visible,
        columnWidth: width,
        fieldGroup: fieldGroupsStringValues[field_group],
        fieldName: field_name
      };
    });

    return columns;
  }
);
export default selectorPropertyDataTableCoordinatorColumns;
