import _ from 'lodash';
import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { createSelector } from 'reselect';
import createCompositeRecord from '../../helpers/createCompositeRecord';
import paginationHelper from '../../helpers/paginationHelper';
import recordCurrentState from '../../helpers/recordCurrentState';
import recordNextState from '../../helpers/recordNextState';
import recordsUpdated from './recordsUpdated.selector';
import recordsChanged from './recordsChanged.selector';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorCompositeUnitRecordsToShow = createSelector(
  [
    paginationSelectorFactory('unit', 'currentPage'),
    paginationSelectorFactory('unit', 'numOfRecordsPerPage'),
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated,
    recordsChanged,
    columnAndRecordConfigsSelectorFactory('fieldTypesNumericalValues'),
    columnAndRecordConfigsSelectorFactory('unitFieldConfigsBasedOnState'),
    columnAndRecordConfigsSelectorFactory('unitColumnConfigsBasedOnState'),
  ],
  (
    currentUnitsPage,
    numOfUnitRecordsPerPage,
    openedPropertyIndex,
    recordsUpdated,
    recordsChanged,
    fieldTypesNumericalValues,
    unitFieldConfigsBasedOnState,
    unitColumnConfigsBasedOnState,
  ) => {
    if (
      !Number.isInteger(openedPropertyIndex) ||
      recordsUpdated.length === 0 ||
      recordsUpdated[openedPropertyIndex]['units'].length === 0
    ) {
      return [];
    }

    const numericsToFieldTypes = _.invert(fieldTypesNumericalValues);

    const { startAt, endAt } = paginationHelper({
      currentPage: currentUnitsPage,
      numOfRecordsPerPage: numOfUnitRecordsPerPage,
      totalNumOfRecords: recordsUpdated[openedPropertyIndex]['units'].length
    });

    const toShow = [];

    const propertyRecord =
      recordsUpdated[openedPropertyIndex][FIELD_CATEGORY_PROPERTY];
    const propertyRecordChanges =
      recordsChanged[openedPropertyIndex][FIELD_CATEGORY_PROPERTY];

    for (let i = startAt, iMax = endAt; i < iMax; i++) {
      const record = recordsUpdated[openedPropertyIndex]['units'][i];
      const recordChanges = recordsChanged[openedPropertyIndex]['units'][i];
      let edit = false

      if (recordsUpdated[openedPropertyIndex]['property']['alx_fields']['field_renewal_status'] === 'With handler for agreed scheme updates') {
        edit = true
      }

      const test = true

      toShow.push(
        createCompositeRecord({
          currentState: recordCurrentState(
            propertyRecord,
            propertyRecordChanges
          ),
          nextState: recordNextState(propertyRecord, propertyRecordChanges),
          record,
          recordChanges,
          columnConfigs: unitColumnConfigsBasedOnState,
          numericsToFieldTypes,
          fieldConfigsBasedOnState: unitFieldConfigsBasedOnState,
          edit,
          test
        })
      );
    }

    return toShow;
  }
);

export default selectorCompositeUnitRecordsToShow;
