import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Typography } from '@material-ui/core';

import services from '../../services';
import UIPaperFullWidth from '../UI/PaperFullWidth';
import UIAnimateAutoHeight from '../UI/AnimateAutoHeight';
import { Snackbar } from '../UI';

const RenewalActionsBarSingleProperty = ({
  actionCloseOpenedProperty,
  actionResetPropertyRecordAndItsUnitRecords,
  actionSubmitRecordChangesForEncryptedResource,
  stateApiCallInprogress,
  stateChangedRecordIndexes,
  stateChangedRenewalRecords,
  stateEncryptedResource,
  stateIsPropertyOpen,
  stateOpenedPropertyIndex,
  stateUpdatedRenewalRecords
}) => {
  const [showSnackBar, setShowSnackbar] = useState(false);
  const recordChangedbyUser = stateChangedRecordIndexes.includes(
    stateOpenedPropertyIndex
  );

  const handleSubmittingChanges = useCallback(() => {
    const preparedRecordChanges = services.renewalRecords.prepareRenewalRecordForUpdate(
      stateChangedRenewalRecords[stateOpenedPropertyIndex],
      stateUpdatedRenewalRecords[stateOpenedPropertyIndex]
    );

    if (preparedRecordChanges === null) return;

    // Some validation for messages
    const alx_fields = _.get(preparedRecordChanges, ['property', 'alx_fields']);
    if (
      alx_fields &&
      Object.prototype.hasOwnProperty.call(
        alx_fields,
        'field_comments_further_information_new'
      )
    ) {
      if (
        Object.prototype.hasOwnProperty.call(
          alx_fields,
          'field_insurance_broker'
        ) &&
        // Is no name
        (!alx_fields.field_insurance_broker.trim().length ||
          // Only first or last name
          alx_fields.field_insurance_broker.trim().split(' ').length <= 1)
      ) {
        setShowSnackbar(true);
        return;
      }

      if (
        Object.prototype.hasOwnProperty.call(
          alx_fields,
          'field_handler_comment_name'
        ) &&
        // Is no name
        (!alx_fields.field_handler_comment_name.trim().length ||
          // Only first or last name
          alx_fields.field_handler_comment_name.trim().split(' ').length <= 1)
      ) {
        setShowSnackbar(true);
        return;
      }
    }

    return actionSubmitRecordChangesForEncryptedResource({
      encryptedResource: stateEncryptedResource,
      recordsToSubmit: [stateOpenedPropertyIndex],
      preparedRecordsChanges: [preparedRecordChanges]
    });
  }, [
    actionSubmitRecordChangesForEncryptedResource,
    stateChangedRenewalRecords,
    stateEncryptedResource,
    stateOpenedPropertyIndex,
    stateUpdatedRenewalRecords
  ]);

  const handleCloseOpenedProperty = useCallback(() => {
    actionCloseOpenedProperty();
  }, [actionCloseOpenedProperty]);

  const handleCloseAndResetOpenedProperty = useCallback(() => {
    actionResetPropertyRecordAndItsUnitRecords({
      propertyRecordIndex: stateOpenedPropertyIndex
    });
    actionCloseOpenedProperty();
  }, [
    actionCloseOpenedProperty,
    actionResetPropertyRecordAndItsUnitRecords,
    stateOpenedPropertyIndex
  ]);

  return (
    <UIAnimateAutoHeight bIsOpen={stateIsPropertyOpen}>
      <UIPaperFullWidth>
        <Box display="flex" padding="1em">
          <Typography component="div" style={{ flexGrow: 1 }}></Typography>
          <Button
            disabled={stateApiCallInprogress || !recordChangedbyUser}
            variant="outlined"
            style={{ marginRight: '1em' }}
            onClick={handleCloseAndResetOpenedProperty}
          >
            undo changes and go back to properties
          </Button>
          <Button
            disabled={stateApiCallInprogress || !recordChangedbyUser}
            variant="outlined"
            color="primary"
            style={{ marginRight: '1em' }}
            onClick={handleCloseOpenedProperty}
          >
            keep changes and go back to properties
          </Button>
          <Button
            disabled={stateApiCallInprogress || !recordChangedbyUser}
            variant="contained"
            color="primary"
            onClick={handleSubmittingChanges}
          >
            Submit Single Record
          </Button>
        </Box>
      </UIPaperFullWidth>
      <Box height="1em" />
      <Snackbar
        {...{
          handleClose: () => setShowSnackbar(false),
          isOpen: showSnackBar,
          message:
            'If you have added a message to this record, please ensure you have provided your first and last name.',
          type: 'warning'
        }}
      />
    </UIAnimateAutoHeight>
  );
};

export default RenewalActionsBarSingleProperty;
