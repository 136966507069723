export default {
  urls: {
    fetchConfig: '/api/configs/fetch_for_encrypted_resource',
    fetchRecords: '/api/records/fetch_for_encrypted_resource',
    fetchPersonalInformation:
      '/api/records/fetch_user_information_for_encrypted_resource',
    saveChangedRecords: '/api/records/save_changes_for_encrypted_resource',
    update: '/api/records/update_target'
  }
};
