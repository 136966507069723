import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import UIDataTableBodyCell from './DataTableBodyCell';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
    justifyContent: 'center',
    height: '56px',
    background: '#f1f1f1'
  },
  select: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    height: '57px'
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    background: '#f1f1f1'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function DataTableCellRecordCheckedFilter({
  columnWidth,
  columnLabel,
  stateUpdatedRenewalRecords,
  checkedValues,
  setCheckedValues,
  handleFieldChange
}) {
  const classes = useStyles();
  const [timer, setTimer] = useState(null);
  const lowercaseColumnLabel = columnLabel.toLowerCase();
  const noSpaceLabel = columnLabel.replace(/ /g, '');

  const occupiedVacant = ['O', 'V'];
  const peril = stateUpdatedRenewalRecords
    .map(record => record.claims)
    .filter(claim => claim.length);
  const occupancy = stateUpdatedRenewalRecords.map(
    record => record.units[0]?.alx_fields.field_u_occupancy
  );
  const currentState = stateUpdatedRenewalRecords.map(
    record => record.property?.alx_fields.field_renewal_status
  );

  const nestedIncrementer = props => {
    let arr = [];
    for (let x = 0; x < props.length; x++) {
      for (let i = 0; i < props[x].length; i++) {
        arr.push(props[x][i].alx_fields.field_c_peril);
      }
    }
    return [...new Set(arr)];
  };

  const incrementer = value => {
    let arr = [];
    for (let i = 0; i <= value; i++) {
      arr.push(i);
    }
    return [...new Set(arr)];
  };

  const handleChange = value => {
    if (typeof value === 'string') {
      value = [value];
    }
    setCheckedValues({
      ...checkedValues,
      [noSpaceLabel]: value
    });
    handleFieldChange(value);
  };

  const timeoutWrapper = value => {
    return () => {
      handleChange(value);
    };
  };

  const options = label => {
    if (label === 'total claims') {
      const r = incrementer(
        stateUpdatedRenewalRecords
          .map(renewal => renewal['claims'].length)
          .reduce((a, b) => Math.max(a, b))
      );
      return Array.isArray(r) ? r : [];
    }
    if (label === 'claims last 12 months') {
      const r = stateUpdatedRenewalRecords.map(
        renewal =>
          renewal.property.coordinator_fields
            .coordinator_field_number_of_claims_12_mos
      );
      return Array.isArray(r) ? [...new Set(r)] : [];
    }
    if (label === 'units') {
      const r = incrementer(
        stateUpdatedRenewalRecords
          .map(renewal => renewal[label].length)
          .reduce((a, b) => Math.max(a, b))
      ).slice(1);
      return Array.isArray(r) ? r : [];
    }
    if (label === 'peril') {
      const r = nestedIncrementer(peril);
      return Array.isArray(r) ? r : [];
    }
    if (label === 'occupied vacant') {
      const r = occupiedVacant;
      return Array.isArray(r) ? r : [];
    }
    if (label === 'occupancy') {
      const r = [...new Set(occupancy)];
      return Array.isArray(r) ? r : [];
    }
    if (label === 'current state') {
      const r = [...new Set(currentState)];
      return Array.isArray(r) ? r : [];
    }
  };

  return (
    <UIDataTableBodyCell width={columnWidth ? columnWidth : 's'} padding="none">
      <FormControl
        width={columnWidth ? columnWidth : 's'}
        variant="filled"
        className={classes.formControl}
      >
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          multiple
          value={
            Array.isArray(checkedValues[noSpaceLabel])
              ? checkedValues[noSpaceLabel]
              : []
          }
          onChange={event => {
            clearTimeout(timer);
            setTimer(setTimeout(timeoutWrapper(event.target.value), 300));
          }}
          className={classes.select}
          input={<Input className={classes.input} />}
        >
          {options(lowercaseColumnLabel).map(v => (
            <MenuItem key={v} value={v} className={classes.menuItem}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </UIDataTableBodyCell>
  );
}
