import { SET_FLOAT } from '../constants';

export const actionSetFloatNumber = (float) => async dispatch => {
    dispatch({ 
        type: SET_FLOAT,
        payload: float
    });
};

export default actionSetFloatNumber;
