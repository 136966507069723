import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import services from '../../services';
import UIBootstrapLikeAlert from './BootstrapLikeAlert';
import UIDataTableBodyCell from './DataTableBodyCell';

export const DataTableBodyCellRecordFieldStatic = props => {
  const {
    columnWidth,
    fieldCompareWithOld,
    fieldLabel,
    fieldName,
    fieldSelectsMap,
    fieldType,
    fieldValue,
    oldFieldValue,
    fieldAmendedValue,
    bIsRecordSelectable,
    preFloatValue
  } = props;
  const inputEl1 = useRef(null);
  const inputEl2 = useRef(null);

  useEffect(() => {
    if (inputEl1.current) {
      const height = inputEl1.current.scrollHeight;
      inputEl1.current.style.height = height + 3 + 'px';
    }
    if (inputEl2.current) {
      const height = inputEl2.current.scrollHeight;
      inputEl2.current.style.height = height + 3 + 'px';
    }
  }, [inputEl1, inputEl2]);

  const showOldValue =
    fieldCompareWithOld && typeof oldFieldValue !== 'undefined';
  const oldValue =
    showOldValue === true
      ? services.renewalRecords.getFieldValue({
          fieldType,
          fieldValue: oldFieldValue,
          fieldLabel,
          fieldSelectsMap
        })
      : null;
  const currentValue = services.renewalRecords.getFieldValue({
    fieldType,
    fieldValue,
    fieldLabel,
    fieldSelectsMap
  });

  const showAmendedValue = !!fieldAmendedValue;
  const request = useSelector(state => state.renewal.user.request);
  const requester = useSelector(state => state.renewal.user.requester);
  let previousValueTitle = 'EXPIRING - ';

  if (fieldName.includes('field_u_asset')) {
    previousValueTitle = 'POLICY - ';
  }
  if (fieldName.includes('premium')) {
    previousValueTitle = 'ORIGINAL - ';
  }

  const renderEndorsements = (value, ref) => {
    return (
      <textarea
        ref={ref}
        className="endorsement-text"
        disabled
        value={value}
      ></textarea>
    );
  };

  const upliftPercentage = (oldValue, newValue) => {
    return Math.abs(((newValue - oldValue) / oldValue) * 100); // toFixed(4) removed L.P 20/07/23
  };

  return (
    <UIDataTableBodyCell width={columnWidth}>
      {showOldValue && currentValue !== oldValue ? (
        <UIBootstrapLikeAlert type="warning" style={{ marginBottom: '1em' }}>
          <div style={{ display: 'flex' }}>
            <strong style={{ display: 'flex', alignConten: 'start' }}>
              {previousValueTitle}{' '}
            </strong>
            {fieldName === 'field_u_endorsements'
              ? renderEndorsements(oldValue, inputEl2)
              : oldValue}
              {/* Changed the oldValue.toFixed(4) - removed the toFixed(4) (l.p 20-07-23)*/}
          </div>
        </UIBootstrapLikeAlert>
      ) : null}

      {!showOldValue && preFloatValue ? (
        <UIBootstrapLikeAlert type="warning" style={{ marginBottom: '1em' }}>
          <span>
            <strong>{previousValueTitle}</strong>
            {preFloatValue}
          </span>
        </UIBootstrapLikeAlert>
      ) : null}

      {showAmendedValue ? (
        <UIBootstrapLikeAlert type="error" style={{ marginBottom: '1em' }}>
          <span>
            <strong>AMENDED - </strong>
            {fieldAmendedValue}
          </span>
        </UIBootstrapLikeAlert>
      ) : null}

      {/* Added by L.P to show the status if nothing applied */}
      {bIsRecordSelectable &&
      request === 'RENEWAL' &&
      (requester === 'HANDLER' || requester === 'ADDS') ? (
        <span>Terms have been applied</span>
      ) : fieldName === 'coordinator_field_next_state' &&
        request === 'RENEWAL' &&
        requester === 'ADDS' && currentValue === oldValue ? (
        <UIBootstrapLikeAlert type="warning" style={{ marginBottom: '1em' }}>
          <span>No terms have been applied!</span>
        </UIBootstrapLikeAlert>
      ) : null}

      {showAmendedValue || (showOldValue && currentValue !== oldValue) ? (
        <UIBootstrapLikeAlert type="info" style={{ marginBottom: '1em' }}>
          <div style={{ display: 'flex' }}>
            <strong style={{ display: 'flex', alignConten: 'start' }}>
              RENEWAL - {' '}
            </strong>
            {fieldName === 'field_u_endorsements'
              ? renderEndorsements(currentValue, inputEl1)
              : currentValue}
              {/* Changed the currentValue.toFixed(4) - removed the toFixed(4) (l.p 20-07-23)*/}
          </div>
        </UIBootstrapLikeAlert>
      ) : (
        <React.Fragment>
          {fieldName === 'field_u_endorsements'
            ? renderEndorsements(currentValue, inputEl1)
            : currentValue}
          {/* Changed the currentValue.toFixed(4) - removed the toFixed(4) (l.p 20-07-23)*/}
        </React.Fragment>
      )}

      {/* Added by L.P to show the uplift percentage */}
      {showAmendedValue ||
        (showOldValue && currentValue !== oldValue && fieldName !== 'field_u_endorsements' && (
          <UIBootstrapLikeAlert type="info">
            <div style={{ display: 'flex' }}>
              <strong style={{ display: 'flex', alignConten: 'start' }}>
                RENEWAL - {' '}
              </strong>
              {upliftPercentage(oldValue, currentValue)}%
            </div>
          </UIBootstrapLikeAlert>
        ))}
    </UIDataTableBodyCell>
  );
};


export default DataTableBodyCellRecordFieldStatic;
