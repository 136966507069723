import { RESET_RENEWAL_CONFIGS, SET_RENEWAL_CONFIGS } from '../../../constants';

const initialState = {
  claimColumnConfigsBasedOnState: {},
  claimFieldConfigsBasedOnState: {},
  editModesBasedOnState: {},
  fieldGroupsNumericalValues: {},
  fieldTypesNumericalValues: {},
  newMessageIndicatorBasedOnState: [],
  propertyColumnConfigsBasedOnState: {},
  propertyFieldConfigsBasedOnState: {},
  showNewMessageBoxBasedOnState: [],
  unitColumnConfigsBasedOnState: {},
  unitFieldConfigsBasedOnState: {}
};

export const renewalConfigsReducer = (
  state = initialState,
  { type, renewalConfigs }
) => {
  switch (type) {
    case RESET_RENEWAL_CONFIGS:
      return {
        ...initialState
      };

    case SET_RENEWAL_CONFIGS: {
      const {
        claim_column_configs_based_on_state,
        claim_field_configs_based_on_state,
        edit_modes_based_on_state,
        field_groups_numerical_values,
        field_types_numerical_values,
        new_message_indicator_based_on_state,
        property_column_configs_based_on_state,
        property_field_configs_based_on_state,
        show_new_message_box_based_on_state,
        unit_column_configs_based_on_state,
        unit_field_configs_based_on_state,
      } = renewalConfigs;

      return {
        ...{
          claimColumnConfigsBasedOnState: claim_column_configs_based_on_state,
          claimFieldConfigsBasedOnState: claim_field_configs_based_on_state,
          editModesBasedOnState: edit_modes_based_on_state,
          fieldGroupsNumericalValues: field_groups_numerical_values,
          fieldTypesNumericalValues: field_types_numerical_values,
          newMessageIndicatorBasedOnState: new_message_indicator_based_on_state,
          propertyColumnConfigsBasedOnState: property_column_configs_based_on_state,
          propertyFieldConfigsBasedOnState: property_field_configs_based_on_state,
          showNewMessageBoxBasedOnState: show_new_message_box_based_on_state,
          unitColumnConfigsBasedOnState: unit_column_configs_based_on_state,
          unitFieldConfigsBasedOnState: unit_field_configs_based_on_state
        }
      };
    }
    default:
      return state;
  }
};

export default renewalConfigsReducer;
