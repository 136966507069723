import { CHANGE_PROPERTY_RECORD_FIELD_VALUE } from '../constants';

export const actionChangePropertyRecordFieldValue = ({
    fieldGroup,
    fieldName,
    fieldValue,
    propertyRecordIndex
}) => {
    
    return async dispatch => {

        dispatch({
            type: CHANGE_PROPERTY_RECORD_FIELD_VALUE,
            fieldGroup,
            fieldName,
            fieldValue,
            propertyRecordIndex
        });
        
    };
};

export default actionChangePropertyRecordFieldValue;
