import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import actionSetFloatNumber from '../../store/actions/setFloatNumber';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import config from '../../config';
import Color from 'color';
import DataTableCellRecordCheckedFilter from './DataTableCellRecordCheckedFilter';
import FloatNumberBox from './FloatNumberBox';

const columnWidth = 's';

const createBaseBackground = colorName => {
  const colorToUse = Color(
    config.styles.colors[colorName] || config.styles.colors['secondary']
  );

  return `${colorToUse.hex()}`;
};

const createLinearGradientBackground = colorName => {
  const colorToUse = Color(
    config.styles.colors[colorName] || config.styles.colors['secondary']
  );

  return `linear-gradient(
    90deg,
    ${colorToUse.hex()} 0%,  
    ${colorToUse.lighten(0.05).hex()} 50%, 
    ${colorToUse.hex()} 100%)`;
};

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 16px;
`;

const StyledFloatContainer = styled.div`
  display: flex;
  position: relative;
`;

const StyledTable = styled.table`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const StyledFilterContainer = styled.tbody`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
`;

const StyledFilter = styled.tr`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;
  height: 120px;
  color: #fff !important;
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
`;

const StyledCell = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 1;
  font-size: 1rem;
  font-weight: 400;
  background: ${({ background }) => {
    return createBaseBackground(background);
  }};
  background: ${({ background }) => {
    return createLinearGradientBackground(background);
  }};
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    },
    '&.MuiButton-root': {
      padding: 0,
      fontSize: 12,
      background: '#1b87c2'
    },
    '&:hover': {
      background: '#1b87c2bf'
    },
    position: 'absolute',
    bottom: 10,
    right: 5,
    textTransform: 'capitalize',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }
}));

const DataTableCustomFiltersContainer = ({
  stateUpdatedRenewalRecords,
  actionSetCurrentPropertiesPage,
  actionSetDataTablesFilterValue,
  checkedValues,
  setCheckedValues,
  actionSetFloatNumber,
  stateRenewalUser
}) => {
  const classes = useStyles();
  const [float, setFloat] = useState();

  return (
    <StyledContainer>
      {stateRenewalUser.requester === 'HANDLER' ||
      stateRenewalUser.requester === 'ADDS' ? (
        <StyledFloatContainer>
          <FloatNumberBox float={float} setFloat={setFloat} />
          <Button
            onClick={() =>
              float > 0 || float < 0 ? actionSetFloatNumber(float) : null
            }
            variant="contained"
            className={classes.root}
            color="primary"
          >
            Apply
          </Button>
        </StyledFloatContainer>
      ) : null}
      <StyledTable>
        <StyledFilterContainer>
          {/* <StyledFilter>
                        <StyledCell>Current State</StyledCell>
                        <DataTableCellRecordCheckedFilter
                            {...{
                                columnWidth,
                                columnLabel: "Current State",
                                stateUpdatedRenewalRecords,
                                fieldValue: {fieldCategory: "property", fieldGroup: "alx_fields", fieldName: "field_renewal_status"},
                                checkedValues,
                                setCheckedValues,
                                handleFieldChange: value => {
                                    actionSetCurrentPropertiesPage(0);
                                    actionSetDataTablesFilterValue({
                                        fieldCategory: "property",
                                        fieldGroup: "alx_fields",
                                        fieldName: "field_renewal_status",
                                        fieldValue: value,
                                        filterType: "claims"
                                    });
                                }
                            }}
                        />
                    </StyledFilter> */}
          <StyledFilter>
            <StyledCell>Occupied Vacant</StyledCell>
            {stateUpdatedRenewalRecords.length ? (
              <DataTableCellRecordCheckedFilter
                {...{
                  columnWidth,
                  columnLabel: 'Occupied Vacant',
                  stateUpdatedRenewalRecords,
                  fieldValue: {
                    fieldCategory: 'property',
                    fieldGroup: 'coordinator_fields',
                    fieldName: 'field_u_occupied_vacant'
                  },
                  checkedValues,
                  setCheckedValues,
                  handleFieldChange: value => {
                    actionSetCurrentPropertiesPage(0);
                    actionSetDataTablesFilterValue({
                      fieldCategory: 'property',
                      fieldGroup: 'coordinator_fields',
                      fieldName: 'field_u_occupied_vacant',
                      fieldValue: value,
                      filterType: 'units'
                    });
                  }
                }}
              />
            ) : null}
          </StyledFilter>
          <StyledFilter>
            <StyledCell>Occupancy</StyledCell>
            <DataTableCellRecordCheckedFilter
              {...{
                columnWidth,
                columnLabel: 'Occupancy',
                stateUpdatedRenewalRecords,
                fieldValue: {
                  fieldCategory: 'property',
                  fieldGroup: 'coordinator_fields',
                  fieldName: 'field_u_occupancy'
                },
                checkedValues,
                setCheckedValues,
                handleFieldChange: value => {
                  actionSetCurrentPropertiesPage(0);
                  actionSetDataTablesFilterValue({
                    fieldCategory: 'property',
                    fieldGroup: 'coordinator_fields',
                    fieldName: 'field_u_occupancy',
                    fieldValue: value,
                    filterType: 'units'
                  });
                }
              }}
            />
          </StyledFilter>
          <StyledFilter>
            <StyledCell>Claims Incurred Peril</StyledCell>
            <DataTableCellRecordCheckedFilter
              {...{
                columnWidth,
                columnLabel: 'Peril',
                stateUpdatedRenewalRecords,
                fieldValue: {
                  fieldCategory: 'property',
                  fieldGroup: 'coordinator_fields',
                  fieldName: 'field_c_peril'
                },
                checkedValues,
                setCheckedValues,
                handleFieldChange: value => {
                  actionSetCurrentPropertiesPage(0);
                  actionSetDataTablesFilterValue({
                    fieldCategory: 'property',
                    fieldGroup: 'coordinator_fields',
                    fieldName: 'field_c_peril',
                    fieldValue: value,
                    filterType: 'claims'
                  });
                }
              }}
            />
          </StyledFilter>
        </StyledFilterContainer>
      </StyledTable>
    </StyledContainer>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { actionSetFloatNumber })(
  DataTableCustomFiltersContainer
);
