import { SET_DATATABLES_CHECKED_FILTER_VALUE } from '../constants';

export const actionSetDataTablesCheckedFilterValue = ({
  fieldCategory,
  fieldGroup,
  fieldName,
  fieldValue
}) => {
  return async dispatch => {
    dispatch({
      type: SET_DATATABLES_CHECKED_FILTER_VALUE,
      fieldCategory,
      fieldGroup,
      fieldName,
      fieldValue
    });
  };
};

export default actionSetDataTablesCheckedFilterValue;
