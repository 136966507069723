import React from 'react';
import UIDataTableBodyCellRecordFieldEditable from './DataTableBodyCellRecordFieldEditable';
import UIDataTableBodyCellRecordFieldStatic from './DataTableBodyCellRecordFieldStatic';

export const UIDataTableBodyCellRecordField = props => {
  const { fieldEditable } = props;

  return (
    <React.Fragment>
      {fieldEditable ? (
        <UIDataTableBodyCellRecordFieldEditable {...props} />
      ) : (
        <UIDataTableBodyCellRecordFieldStatic {...props} />
      )}
    </React.Fragment>
  );
};

export default UIDataTableBodyCellRecordField;
