import { FIELD_GROUP_COORDINATOR_FIELDS } from '../consts';

export const recordCurrentState = (record, recordChanges) => {
  return (
    recordChanges[FIELD_GROUP_COORDINATOR_FIELDS][
    'coordinator_field_current_state'
    ] ||
    record[FIELD_GROUP_COORDINATOR_FIELDS]['coordinator_field_current_state']
  );
};

export default recordCurrentState;
