import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { Renewal } from './views';

import './App.css';

import store from './store';
import config from './config';

const theme = createTheme({
  palette: {
    primary: {
      main: config.styles.colors.primary
    },
    secondary: {
      main: config.styles.colors.secondary
    }
  }
});

let App = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Provider store={store.store}>
          <Router>
            <Route path="/renewal" component={Renewal} />
          </Router>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
