import _ from 'lodash';
import { createSelector } from 'reselect';
import { FIELD_GROUP_ALX_FIELDS } from '../../consts';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';

export const selectorUnitDataTableAlxColumns = createSelector(
  [
    columnAndRecordConfigsSelectorFactory([
      'unitColumnConfigsBasedOnState',
      FIELD_GROUP_ALX_FIELDS
    ]),
    columnAndRecordConfigsSelectorFactory('fieldGroupsNumericalValues')
  ],
  (alxColumnConfigsBasedOnState, fieldGroupsNumericalValues) => {
    const fieldGroupsStringValues = _.invert(fieldGroupsNumericalValues);
    const columns = [];

    _.map(alxColumnConfigsBasedOnState, alxColumnConfig => {
      const {
        column_label,
        field_group,
        field_name,
        interactive,
        order,
        visible,
        width
      } = alxColumnConfig;

      if (!visible) {
        return;
      }

      columns.push({
        columnInteractive: interactive,
        columnLabel: column_label,
        columnVisible: visible,
        columnWidth: width,
        fieldGroup: fieldGroupsStringValues[field_group],
        fieldName: field_name,
        order: order
      });
    });

    return _.orderBy(columns, 'order');
  }
);

export default selectorUnitDataTableAlxColumns;
