import { SET_DATATABLES_FILTER_VALUE } from '../constants';

export const actionSetDataTablesFilterValue = ({
  fieldCategory,
  fieldGroup,
  fieldName,
  fieldValue,
  filterType
}) => {
  return async dispatch => {
    dispatch({
      type: SET_DATATABLES_FILTER_VALUE,
      fieldCategory,
      fieldGroup,
      fieldName,
      fieldValue,
      filterType
    });
  };
};

export default actionSetDataTablesFilterValue;
