import { FIELD_GROUP_ALX_FIELDS } from '../../consts';
import { createSelector } from 'reselect';
import recordsUpdated from './recordsUpdated.selector';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorShowOldMessages = createSelector(
  [
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated
  ],
  (openedPropertyIndex, recordsUpdated) => {
    if (!Number.isInteger(openedPropertyIndex)) {
      return false;
    }

    const oldMessages =
      recordsUpdated[openedPropertyIndex]['property'][FIELD_GROUP_ALX_FIELDS][
      'field_comments_further_information_history'
      ];

    return !!oldMessages;
  }
);

export default selectorShowOldMessages;
