import { SET_CURRENT_CLAIMS_PAGE } from '../constants';

export const actionSetCurrentClaimsPage = currentPage => {
  return async dispatch => {
    dispatch({
      type: SET_CURRENT_CLAIMS_PAGE,
      currentPage
    });
  };
};

export default actionSetCurrentClaimsPage;
