import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { createSelector } from 'reselect';
import filterRecords from '../../helpers/filterRecords';
import recordsUpdated from './recordsUpdated.selector';
import recordsChanged from './recordsChanged.selector';
import filterSelectorFactory from './dataTable/filterFactory.selector';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorNumOfTotalPropertyRecords = createSelector(
  [
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated,
    recordsChanged,
    filterSelectorFactory('property')
  ],
  (openedPropertyIndex, recordsUpdated, recordsChanged, dataTableFilter) => {
    if (Number.isInteger(openedPropertyIndex)) {
      return 1;
    }

    const filteredRecordIndexes = filterRecords({
      fieldCategory: FIELD_CATEGORY_PROPERTY,
      dataTableFilter,
      recordsUpdated,
      recordsChanged
    });

    return filteredRecordIndexes.length;
  }
);

export default selectorNumOfTotalPropertyRecords;
