import React from 'react';
import { OutlinedInput, Select, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../config';

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border: 0;
  }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  background: #fff;
  cursor: pointer;
  background: ${config.styles.colors.inputHighlight};
`;

export const DataTableBodyCellRecordFieldEditableSelect = ({
  fieldValue,
  fieldSelects,
  handleFieldChange
}) => {
  return (
    <StyledSelect
      value={fieldValue}
      onChange={event => {
        handleFieldChange(event.target.value);
      }}
      input={<StyledOutlinedInput />}
    >
      {fieldSelects.map(([selectFieldValue, selectFieldValueLabel], index) => (
        <MenuItem key={`select-${index}`} value={selectFieldValue}>
          {selectFieldValueLabel}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default DataTableBodyCellRecordFieldEditableSelect;
