import React, { useCallback } from 'react';
import { TableRow } from '@material-ui/core';
import config from '../../config';
import UIDataTableBodyCellIsRecordEdited from '../UI/DataTableBodyCellIsRecordEdited';
import UIDataTableBodyCellRecordField from '../UI/DataTableBodyCellRecordField';
import { FIELD_GROUP_ALX_FIELDS } from '../../consts';

const RenewalDataTableUnitsDataRow = ({
  actionChangeUnitRecordFieldValue,
  compositeRecord,
  propertyRecordIndex,
  stateUnitDataTableAlxColumns
}) => {
  const unitRecordIndex = compositeRecord['recordIndex'];

  const handleAlxFieldChange = useCallback(
    fieldName => {
      return newValue => {
        return actionChangeUnitRecordFieldValue({
          propertyRecordIndex,
          unitRecordIndex,
          fieldGroup: FIELD_GROUP_ALX_FIELDS,
          fieldName: fieldName,
          fieldValue: newValue
        });
      };
    },
    [actionChangeUnitRecordFieldValue, propertyRecordIndex, unitRecordIndex]
  );

  return (
    <TableRow
      style={{
        backgroundColor: compositeRecord['doesRecordDifferFromOriginal']
          ? config.styles.colors.backgroundHighlight
          : null
      }}
    >
      <UIDataTableBodyCellIsRecordEdited
        showIcon={compositeRecord['doesRecordDifferFromOriginal']}
      />

      {stateUnitDataTableAlxColumns.map(
        ({ columnVisible, columnWidth, fieldName }, index) => {
          const fieldConfig =
            compositeRecord[FIELD_GROUP_ALX_FIELDS][fieldName];

          let preFloatValue;

          if (
            compositeRecord.pre_float_alx_fields &&
            compositeRecord.pre_float_alx_fields[fieldName]
          ) {
            preFloatValue = compositeRecord.pre_float_alx_fields[fieldName];
          }

          return (
            <React.Fragment key={`unlocked-column-td-cell-${index}`}>
              {columnVisible ? (
                <UIDataTableBodyCellRecordField
                  {...{
                    columnWidth,
                    fieldName,
                    ...fieldConfig,
                    preFloatValue,
                    handleFieldChange: handleAlxFieldChange(
                      fieldConfig['fieldName']
                    )
                  }}
                />
              ) : null}
            </React.Fragment>
          );
        }
      )}
    </TableRow>
  );
};

export default RenewalDataTableUnitsDataRow;
