import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import config from '../../config';

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border: 0;
  }
  .MuiOutlinedInput-input {
    background: ${config.styles.colors.inputHighlight};
  }
`;

export const DataTableBodyCellRecordFieldEditableText = ({
  fieldValue,
  handleFieldChange
}) => {
  const [value, setValue] = useState('');
  const [timer, setTimer] = useState(null);

  const timeoutWrapper = newValue => {
    return () => {
      handleFieldChange(newValue);
    };
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return (
    <StyledTextField
      value={value}
      variant="outlined"
      style={{
        padding: '3px',
        background: '#fff'
      }}
      onChange={event => {
        clearTimeout(timer);
        setValue(event.target.value);
        setTimer(setTimeout(timeoutWrapper(event.target.value), 300));
      }}
    />
  );
};

export default DataTableBodyCellRecordFieldEditableText;
