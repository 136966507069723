import { createSelector } from 'reselect';
import recordsChanged from './recordsChanged.selector';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';

export const selectorShowNewCommentBox = createSelector(
  [
    recordsChanged,
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    columnAndRecordConfigsSelectorFactory('showNewMessageBoxBasedOnState')
  ],
  (recordsChanged, openedPropertyIndex, showNewMessageBoxBasedOnState) => {
    if (!Number.isInteger(openedPropertyIndex)) {
      return false;
    }

    const nextState =
      recordsChanged[openedPropertyIndex]['property']['coordinator_fields'][
      'coordinator_field_next_state'
      ];

    return showNewMessageBoxBasedOnState.includes(nextState);
  }
);

export default selectorShowNewCommentBox;
