import {
  RESET_DATATABLES_FILTER,
  SET_DATATABLES_FILTER_VALUE,
} from '../../../../constants';
import _ from 'lodash';
import { FIELD_CATEGORY_PROPERTY } from '../../../../../consts';

const initialState = {
  [FIELD_CATEGORY_PROPERTY]: {}
};

export const dataTablesFilterReducer = (
  state = initialState,
  props
) => {
  let { type, fieldCategory, fieldGroup, fieldName, fieldValue } = props
  switch (type) {
    case RESET_DATATABLES_FILTER:
      return _.cloneDeep(initialState);

    case SET_DATATABLES_FILTER_VALUE: {
      const clonedFilter = JSON.parse(JSON.stringify(state));

      fieldValue === ''
        ? _.unset(clonedFilter, [fieldCategory, fieldGroup, fieldName])
        : _.set(
          clonedFilter,
          [fieldCategory, fieldGroup, fieldName],
          fieldValue
        );

      if (_.size(clonedFilter[fieldCategory][fieldGroup]) === 0) {
        _.unset(clonedFilter, [fieldCategory, fieldGroup]);
      }

      return clonedFilter;
    }

    default:
      return state;
  }
};

export default dataTablesFilterReducer;
