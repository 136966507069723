import _ from 'lodash';
import {
  FIELD_GROUP_ALX_FIELDS,
  FIELD_GROUP_COORDINATOR_FIELDS,
  FIELD_GROUP_ORIGINAL_ALX_FIELDS,
  FIELD_GROUP_AMENDED_ALX_FIELDS
} from '../consts';

export const createCompositeRecord = ({
  columnConfigs,
  currentState,
  fieldConfigsBasedOnState,
  nextState,
  numericsToFieldTypes,
  record,
  recordChanges,
  edit,
  //test
}) => {
  let final

  if (recordChanges.pre_float_alx_fields !== {}) {
    final = {
      [FIELD_GROUP_ALX_FIELDS]: {},
      [FIELD_GROUP_COORDINATOR_FIELDS]: {},
      'pre_float_alx_fields': recordChanges.pre_float_alx_fields
    };
  } else {
    final = {
      [FIELD_GROUP_ALX_FIELDS]: {},
      [FIELD_GROUP_COORDINATOR_FIELDS]: {},
    };
  }

  let isRecordInEditmode

  isRecordInEditmode = currentState !== nextState;

  if (edit) {
    isRecordInEditmode = true
  }

  _.forEach(columnConfigs[FIELD_GROUP_COORDINATOR_FIELDS], ({ field_name }) => {
    const fieldName = field_name;

    const fieldValue = _.isNil(
      recordChanges[FIELD_GROUP_COORDINATOR_FIELDS][fieldName]
    )
      ? record[FIELD_GROUP_COORDINATOR_FIELDS][fieldName]
      : recordChanges[FIELD_GROUP_COORDINATOR_FIELDS][fieldName];

    const {
      compare_with_old,
      editable,
      label,
      selects,
      selects_map,
      type
    } = fieldConfigsBasedOnState[FIELD_GROUP_COORDINATOR_FIELDS][fieldName][
      currentState
      ];

    const fieldType = numericsToFieldTypes[type];

    final[FIELD_GROUP_COORDINATOR_FIELDS][field_name] = {
      fieldCompareWithOld: compare_with_old,
      fieldEditable: editable || false,
      fieldLabel: label,
      fieldName,
      fieldSelects: selects,
      fieldSelectsMap: selects_map,
      fieldType,
      fieldValue
    };
  });

  _.forEach(columnConfigs[FIELD_GROUP_ALX_FIELDS], ({ field_name }) => {
    const fieldName = field_name;
    const fieldValue = _.isNil(recordChanges[FIELD_GROUP_ALX_FIELDS][fieldName])
      ? record[FIELD_GROUP_ALX_FIELDS][fieldName]
      : recordChanges[FIELD_GROUP_ALX_FIELDS][fieldName];
    const fieldOldValue = record[FIELD_GROUP_ORIGINAL_ALX_FIELDS][fieldName];
    const fieldAmendedValue = record[FIELD_GROUP_AMENDED_ALX_FIELDS][fieldName];


    const {
      compare_with_old,
      editable,
      label,
      selects,
      selects_map,
      type
    } = fieldConfigsBasedOnState[FIELD_GROUP_ALX_FIELDS][fieldName][nextState];
    const fieldType = numericsToFieldTypes[type];

    final[FIELD_GROUP_ALX_FIELDS][field_name] = {
      fieldCompareWithOld: compare_with_old,
      fieldEditable: isRecordInEditmode ? editable : false,
      fieldLabel: label,
      fieldName,
      fieldSelects: selects,
      fieldSelectsMap: selects_map,
      fieldType,
      fieldValue,
      oldFieldValue: fieldOldValue,
      fieldAmendedValue: fieldAmendedValue
    };
  });

  final['recordIndex'] = recordChanges['meta']['index'];
  final['currentState'] = currentState;
  final['nextState'] = nextState;
  //final['isInEditMode'] = isRecordInEditmode;
  final['hasMessages'] = !!record[FIELD_GROUP_ALX_FIELDS][
    'field_comments_further_information_history'
  ];
  final['doesRecordDifferFromOriginal'] =
    0 < record[FIELD_GROUP_COORDINATOR_FIELDS]['coordinator_field_has_changes'];

  return final;
};

export default createCompositeRecord;
