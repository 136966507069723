import { SET_NUMBER_OF_UNITS_TO_SHOW_PER_PAGE } from '../constants';

export const actionSetNumOfUnitsToShowPerPage = numOfRecordsPerPage => {
  return async dispatch => {
    dispatch({
      type: SET_NUMBER_OF_UNITS_TO_SHOW_PER_PAGE,
      numOfRecordsPerPage
    });
  };
};

export default actionSetNumOfUnitsToShowPerPage;
