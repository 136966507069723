import { SET_FLOAT_INCREASE, OPEN_SUCCESS_SNACKBAR } from '../constants';

export const actionSetFloatIncrease = (stateFilteredIndexes, stateFloat) => async dispatch => {
    dispatch({
        type: SET_FLOAT_INCREASE,
        stateFilteredIndexes,
        stateFloat,
    });
    dispatch({
        type: OPEN_SUCCESS_SNACKBAR,
        message: 'Float % successfully applied!'
    });
};

export default actionSetFloatIncrease;
