import React from 'react';
import _ from 'lodash';
import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { TableRow, Tooltip } from '@material-ui/core';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import IconButton from '@material-ui/core/IconButton';
import UIDataTableBodyCell from '../UI/DataTableBodyCell';
import UIDataTableBodyCellFilter from '../UI/DataTableBodyCellFilter';
import UIDataTableCellRecordCheckedFilter from '../UI/DataTableCellRecordCheckedFilter';
import { connect } from 'react-redux';
import { setAllowedNextActions } from '../../store/actions/setAllowedNextActions';

const RenewalDataTablePropertiesFilterRow = ({
  actionSetCurrentPropertiesPage,
  actionSetDataTablesFilterValue,
  stateDataTablesFilter,
  statePropertyDataTableAlxColumns,
  stateIsPropertyOpen,
  statePropertyDataTableCoordinatorColumns,
  stateUpdatedRenewalRecords,
  checkedValues,
  setCheckedValues,
  user,
  // Actions
  setAllowedNextActions,
  resetFilters
}) => {
  //console.log("🚀 ~ file: DataTablePropertiesFilterRow.jsx ~ line 20 ~ stateDataTablesFilter", stateDataTablesFilter)
  //console.log("🚀 ~ file: DataTablePropertiesFilterRow.jsx ~ line 21 ~ statePropertyDataTableCoordinatorColumns", statePropertyDataTableCoordinatorColumns)

  const handleSelectAllowed = () => {
    setAllowedNextActions();
  };

  const { requester, request } = user;

  return (
    <TableRow>
      <UIDataTableBodyCell width="xs" padding="none">
        <div
          onClick={() => resetFilters()}
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Tooltip title="Reset All Filters" placement="top">
            <IconButton>
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
      </UIDataTableBodyCell>
      <UIDataTableBodyCell width="xs" padding="none">
        {requester === 'INSURER' && request === 'RENEWAL' ? (
          <div
            onClick={handleSelectAllowed}
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <Tooltip
              title='Select All allowed properties to "Leave terms unchanged".'
              placement="right"
            >
              <IconButton>
                <SelectAllIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
      </UIDataTableBodyCell>
      {stateIsPropertyOpen ? null : (
        <UIDataTableBodyCell width="xs" padding="none" />
      )}
      <UIDataTableBodyCell width="xs" padding="none" />
      {/*<UIDataTableBodyCell width="xs" padding="none" />*/}
      {Object.values(statePropertyDataTableCoordinatorColumns).map(
        (
          {
            columnVisible,
            columnHasFilter,
            columnWidth,
            fieldGroup,
            columnLabel,
            fieldName
          },
          i
        ) => {
          return (
            <React.Fragment key={`unlocked-column-th-${i}`}>
              {columnVisible ? (
                <React.Fragment>
                  {columnHasFilter && stateUpdatedRenewalRecords.length ? (
                    <UIDataTableCellRecordCheckedFilter
                      {...{
                        columnWidth,
                        columnLabel,
                        stateUpdatedRenewalRecords,
                        fieldValue: _.get(
                          stateDataTablesFilter,
                          [FIELD_CATEGORY_PROPERTY, fieldGroup, fieldName],
                          ''
                        ),
                        checkedValues,
                        setCheckedValues,
                        handleFieldChange: value => {
                          actionSetCurrentPropertiesPage(0);
                          actionSetDataTablesFilterValue({
                            fieldCategory: FIELD_CATEGORY_PROPERTY,
                            fieldGroup,
                            fieldName,
                            fieldValue: value,
                            filterType: 'properties'
                          });
                        }
                      }}
                    />
                  ) : (
                    <UIDataTableBodyCell width={columnWidth} />
                  )}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        }
      )}
      {statePropertyDataTableAlxColumns.map(
        (
          {
            columnVisible,
            columnHasFilter,
            columnWidth,
            fieldGroup,
            fieldName
          },
          i
        ) => {
          return (
            <React.Fragment key={`unlocked-column-th-${i}`}>
              {columnVisible ? (
                <React.Fragment>
                  {columnHasFilter ? (
                    <UIDataTableBodyCellFilter
                      {...{
                        columnWidth,
                        fieldValue: _.get(
                          stateDataTablesFilter,
                          [FIELD_CATEGORY_PROPERTY, fieldGroup, fieldName],
                          ''
                        ),
                        handleFieldChange: value => {
                          actionSetCurrentPropertiesPage(0);
                          actionSetDataTablesFilterValue({
                            fieldCategory: FIELD_CATEGORY_PROPERTY,
                            fieldGroup,
                            fieldName,
                            fieldValue: value,
                            filterType: 'properties'
                          });
                        }
                      }}
                    />
                  ) : (
                    <UIDataTableBodyCell width={columnWidth} />
                  )}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        }
      )}
    </TableRow>
  );
};

const mapStateToProps = state => ({
  user: state.renewal.user
});

const mapDispatchToProps = dispatch => ({
  setAllowedNextActions: () => dispatch(setAllowedNextActions()),
  resetFilters: () => dispatch({ type: 'RESET_DATATABLES_FILTER' })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewalDataTablePropertiesFilterRow);
