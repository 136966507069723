import { createSelector } from 'reselect';
import recordsUpdated from './recordsUpdated.selector';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorNumOfTotalClaimRecords = createSelector(
  [
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated
  ],
  (openedPropertyIndex, recordsUpdated) => {
    if (openedPropertyIndex === null) {
      return 0;
    }

    return recordsUpdated[openedPropertyIndex]['claims'].length;
  }
);

export default selectorNumOfTotalClaimRecords;
