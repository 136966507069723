import { SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE } from '../constants';

export const actionSetNumOfPropertiesToShowPerPage = numOfRecordsPerPage => {
  return async dispatch => {
    dispatch({
      type: SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE,
      numOfRecordsPerPage
    });
  };
};

export default actionSetNumOfPropertiesToShowPerPage;
