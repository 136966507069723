import _ from 'lodash';
import { FIELD_GROUP_ALX_FIELDS, FIELD_GROUP_COORDINATOR_FIELDS } from '../../consts';
import { createSelector } from 'reselect';
import recordsUpdatedSelector from './recordsUpdated.selector';
import recordsChangedSelector from './recordsChanged.selector';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';

export const selectorChangedRecordIndexes = createSelector(
  [
    recordsUpdatedSelector,
    recordsChangedSelector,
    columnAndRecordConfigsSelectorFactory('editModesBasedOnState')
  ],
  (recordsUpdated, recordsChanged, editModesBasedOnState) => {
    const final = [];
    _.times(recordsChanged.length, index => {
      const updatedRecord = recordsUpdated[index];
      const changedRecord = recordsChanged[index];

      const currentState =
        updatedRecord['property'][FIELD_GROUP_COORDINATOR_FIELDS][
        'coordinator_field_current_state'
        ];
      const nexState =
        changedRecord['property'][FIELD_GROUP_COORDINATOR_FIELDS][
        'coordinator_field_next_state'
        ];

      if (currentState === nexState) {
        return;
      }

      const propertyRecordChangedByUser =
        editModesBasedOnState[nexState] === false ||
        0 <
        _.chain(recordsChanged)
          .get([index, 'property', FIELD_GROUP_ALX_FIELDS])
          .size()
          .value();

      if (propertyRecordChangedByUser) {
        return final.push(recordsChanged[index]['property']['meta']['index']);
      }

      const unitRecordChangedByUser =
        0 <
        _.chain(recordsChanged)
          .get([index, 'units'])
          .filter(changedUnitRecord => {
            return (
              0 <
              _.chain(changedUnitRecord)
                .get([FIELD_GROUP_ALX_FIELDS])
                .size()
                .value()
            );
          })
          .value().length;

      if (unitRecordChangedByUser) {
        return final.push(recordsChanged[index]['property']['meta']['index']);
      }
    });

    return final;
  }
);

export default selectorChangedRecordIndexes;
