import React from 'react';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';
import UIDataTableBodyCell from './DataTableBodyCell';
import { Tooltip } from '@material-ui/core';

export const UIDataTableBodyCellOpenRecord = ({ handleClick, isOpen }) => {
  return (
    <UIDataTableBodyCell
      width="xs"
      padding="none"
      cursorify="true"
      onClick={handleClick}
    >
      <UIFlexboxCenterPositioner>
        {isOpen ? (
          <Tooltip title="Back to properties table.">
            <VisibilityOffIcon />
          </Tooltip>
        ) : (
          <Tooltip title="View propery record, units & claims.">
            <VisibilityIcon />
          </Tooltip>
        )}
      </UIFlexboxCenterPositioner>
    </UIDataTableBodyCell>
  );
};

export default UIDataTableBodyCellOpenRecord;
