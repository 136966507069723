import React from 'react';
import { FormControl } from '@material-ui/core';
import styled from 'styled-components';
import services from '../../services';
import config from '../../config';
import {
  FIELD_TYPE_DOUBLE,
  FIELD_TYPE_FREEFORM,
  FIELD_TYPE_LABEL,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_TEXTAREA
  /*,
  FIELD_TYPE_DATE*/
} from '../../consts';
import UIBootstrapLikeAlert from './BootstrapLikeAlert';
import UIDataTableBodyCell from './DataTableBodyCell';
import UIDataTableBodyCellRecordFieldEditableDouble from './DataTableBodyCellRecordFieldEditableDouble';
import UIDataTableBodyCellRecordFieldEditableLabel from './DataTableBodyCellRecordFieldEditableLabel';
import UIDataTableBodyCellRecordFieldEditableSelect from './DataTableBodyCellRecordFieldEditableSelect';
import UIDataTableBodyCellRecordFieldEditableText from './DataTableBodyCellRecordFieldEditableText';
import UIDataTableBodyCellRecordFieldEditableTextArea from './DataTableBodyCellRecordFieldEditableTextArea';

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiFormControl-root {
    padding: 0 !important;
  }
`;

const render = props => {
  const { fieldType } = props;

  switch (fieldType) {
    case FIELD_TYPE_FREEFORM:
      return <UIDataTableBodyCellRecordFieldEditableText {...props} />;

    case FIELD_TYPE_DOUBLE:
      return <UIDataTableBodyCellRecordFieldEditableDouble {...props} />;

    case FIELD_TYPE_LABEL:
      return <UIDataTableBodyCellRecordFieldEditableLabel {...props} />;

    case FIELD_TYPE_SELECT:
      return <UIDataTableBodyCellRecordFieldEditableSelect {...props} />;

    case FIELD_TYPE_TEXTAREA:
      return <UIDataTableBodyCellRecordFieldEditableTextArea {...props} />;

    default:
      return <React.Fragment>No data</React.Fragment>;
  }
};

export const DataTableBodyCellRecordFieldEditable = ({
  columnWidth,
  fieldCompareWithOld,
  fieldLabel,
  fieldName,
  fieldSelects,
  fieldSelectsMap,
  fieldType,
  fieldValue,
  handleFieldChange,
  oldFieldValue,
  fieldAmendedValue,
  preFloatValue
}) => {
  const showOldValue =
    fieldCompareWithOld && typeof oldFieldValue !== 'undefined';
  const oldValue =
    showOldValue === true
      ? services.renewalRecords.getFieldValue({
          fieldType,
          fieldValue: oldFieldValue,
          fieldLabel,
          fieldSelectsMap
        })
      : null;
  const showAmendedValue = !!fieldAmendedValue;
  let previousValueTitle = 'EXPIRING - ';

  if (fieldName.includes('field_u_asset')) {
    previousValueTitle = 'POLICY - ';
  }
  if (fieldName.includes('premium')) {
    previousValueTitle = 'ORIGINAL - ';
  }

  return (
    <UIDataTableBodyCell
      width={columnWidth}
      padding="none"
      style={{ background: config.styles.colors.inputHighlight }}
    >
      {showOldValue ? (
        <UIBootstrapLikeAlert type="warning">
          <span>
            <strong>{previousValueTitle}</strong>
            {oldValue}
          </span>
        </UIBootstrapLikeAlert>
      ) : null}

      {!showOldValue && preFloatValue ? (
        <UIBootstrapLikeAlert type="warning">
          <span>
            <strong>{previousValueTitle}</strong>
            {preFloatValue}
          </span>
        </UIBootstrapLikeAlert>
      ) : null}

      {showAmendedValue ? (
        <UIBootstrapLikeAlert type="error">
          <span>
            <strong>AMENDED - </strong>
            {fieldAmendedValue}
          </span>
        </UIBootstrapLikeAlert>
      ) : null}

      <StyledFormControl>
        {render({
          fieldLabel,
          fieldSelects,
          fieldType,
          fieldValue,
          handleFieldChange,
          oldFieldValue
        })}
      </StyledFormControl>
    </UIDataTableBodyCell>
  );
};

export default DataTableBodyCellRecordFieldEditable;
