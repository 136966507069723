import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1rem;
    font-weight: 400;
`

const StyledHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient( 90deg, #0085C0 0%, #008CCA 50%, #0085C0 100%);
    color: #fff !important;
    padding: 22px;
`

const StyledInput = styled.input`
    border-radius: 0;
    border: none;
    padding: 19px;
    background: #f1f1f1;
    border-bottom: 1px solid #9c9c9c;
`

const FloatNumberBox = ({ float, setFloat }) => {
    return (
        <StyledContainer>
            <StyledHeader>Premium Rate %</StyledHeader>
            <StyledInput type='number' value={float === 0 ? null : float} onChange={({ target }) => setFloat(Number(target.value))}></StyledInput>
        </StyledContainer>
    )
}

export default FloatNumberBox
