export const setAllowedNextActions = () => {
    return (dispatch, getState) => {
        const properties = getState().renewal.records.updated
        for (let i = 0; i < properties.length; i++) {
            const cs = properties[i].property.coordinator_fields.coordinator_field_current_state
            const ns = properties[i].property.coordinator_fields.coordinator_field_next_state
            if (cs === 1 && ns === 1) {
                dispatch({
                    type: "RESET_RENEWAL_RECORD",
                    propertyRecordIndex: i
                })
                dispatch({
                    type: "CHANGE_PROPERTY_RECORD_FIELD_VALUE",
                    fieldGroup: "coordinator_fields",
                    fieldName: "coordinator_field_next_state",
                    fieldValue: 5,
                    propertyRecordIndex: i
                })
                dispatch({
                    type:"TOGGLE_SELECTED_PROPERTY",
                    selectedPropertyIndex: i
                })
            }
        }
    }
}