import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainbox: {
    margin: 'auto',
    height: 'calc(100vh - 16px)',
    width: 'calc(100vw - 16px)',
    position: 'relative',
    fontFamily: 'sans-serif',
    color: '#222',
    textAlign: 'center',
    backgroundColor: '#fdfdfd'
  },
  mainboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  main: {
    fontSize: '8rem',
    marginBottom: '3rem',
    color: '#0085C0'
  },
  msg: {
    fontSize: '1.3rem',
    marginBottom: '1rem'
  },
  wrapper: {
    minHeight: '50vh'
  },
  svgBacking: {
    position: 'absolute',
    left: 0,
    bottom: 0
  }
}));

const NotFound404 = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainbox}>
      <div className={classes.mainboxWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.main}>404</div>
          <div className={classes.msg}>
            Oops, it seems this page cannot be found!
          </div>
          <div className={classes.msg}>
            If this resource exists, please ensure you have the access rights to
            view it.
          </div>
        </div>
      </div>
      <svg
        className={classes.svgBacking}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="#0085C0"
          fillOpacity="1"
          d="M0,128L48,128C96,128,192,128,288,149.3C384,171,480,213,576,234.7C672,256,768,256,864,224C960,192,1056,128,1152,117.3C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <svg
        className={classes.svgBacking}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="rgba(29,157,214, 85%)"
          fillOpacity="1"
          d="M0,256L48,245.3C96,235,192,213,288,186.7C384,160,480,128,576,117.3C672,107,768,117,864,133.3C960,149,1056,171,1152,154.7C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default NotFound404;
