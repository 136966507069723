import React, { useCallback, useState } from 'react';
import { Box, TableBody, TableRow, TableHead } from '@material-ui/core';
import UIDataTableHeadCellRecordField from '../UI/DataTableHeadCellRecordField';
import UIDataTableFullWidth from '../UI/DataTableFullWidth';
import UIPaperFullWidth from '../UI/PaperFullWidth';
import UIDataTableContainerFullWidthOverflow from '../UI/DataTableContainerFullWidthOverflow';
import UIDataTableAppBar from '../UI/DataTableAppBar';
import UIDataTablePagination from '../UI/DataTablePagination';
import UIAnimateAutoHeight from '../UI/AnimateAutoHeight';
import RenewalDataTableClaimsDataRow from './DataTableClaimsDataRow';

const RenewalDataTableClaims = ({
  actionSetCurrentClaimsPage,
  actionSetNumOfClaimsToShowPerPage,
  stateClaimDataTableAlxColumns,
  stateCompositeClaimRecordsToShow,
  stateCurrentClaimsPage,
  stateIsPropertyOpen,
  stateNumOfClaimRecordsPerPage,
  stateNumOfTotalClaimRecords
}) => {
  const [bShowDataTable, setShowDataTable] = useState(true);

  const handleToggleShowDataTable = useCallback(() => {
    return setShowDataTable(!bShowDataTable);
  }, [bShowDataTable, setShowDataTable]);

  return (
    <UIAnimateAutoHeight bIsOpen={stateIsPropertyOpen}>
      <UIDataTableAppBar
        {...{
          bEnableFeatureHideDataTable: true,
          bShowDataTable,
          handleToggleShowDataTable,
          iconName: 'ChromeReaderModeIcon',
          numOfRecords: stateNumOfTotalClaimRecords,
          titleAppBar: 'CLAIMS',
          titleTooltip: 'Number of Claims'
        }}
      />

      <React.Fragment>
        {bShowDataTable ? (
          <React.Fragment>
            <Box display="flex">
              <UIPaperFullWidth>
                <UIDataTablePagination
                  {...{
                    bShowPagination: stateIsPropertyOpen,
                    currentPage: stateCurrentClaimsPage,
                    handleSetCurrentPage: actionSetCurrentClaimsPage,
                    handleSetNumOfRecordsToShowPerPage: actionSetNumOfClaimsToShowPerPage,
                    numOfRecordsPerPage: stateNumOfClaimRecordsPerPage,
                    numOfRowsPerPageOptions: [5, 10, 25, 50],
                    numOfTotalRecords: stateNumOfTotalClaimRecords
                  }}
                />
                <UIDataTableContainerFullWidthOverflow>
                  <UIDataTableFullWidth>
                    <TableHead>
                      <TableRow>
                        {stateClaimDataTableAlxColumns.map(
                          ({ columnLabel, columnVisible }, i) => {
                            return (
                              <React.Fragment key={`unlocked-column-th-${i}`}>
                                {columnVisible ? (
                                  <UIDataTableHeadCellRecordField
                                    label={columnLabel}
                                    handleClick={() => {}}
                                  />
                                ) : null}
                              </React.Fragment>
                            );
                          }
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {stateCompositeClaimRecordsToShow.map(
                        (compositeRecord, index) => {
                          return (
                            <RenewalDataTableClaimsDataRow
                              key={`table-row-${index}`}
                              {...{
                                compositeRecord,
                                stateClaimDataTableAlxColumns
                              }}
                            />
                          );
                        }
                      )}
                    </TableBody>
                  </UIDataTableFullWidth>
                </UIDataTableContainerFullWidthOverflow>
                <UIDataTablePagination
                  {...{
                    bShowPagination: stateIsPropertyOpen,
                    currentPage: stateCurrentClaimsPage,
                    handleSetCurrentPage: actionSetCurrentClaimsPage,
                    handleSetNumOfRecordsToShowPerPage: actionSetNumOfClaimsToShowPerPage,
                    numOfRecordsPerPage: stateNumOfClaimRecordsPerPage,
                    numOfRowsPerPageOptions: [5, 10, 25, 50],
                    numOfTotalRecords: stateNumOfTotalClaimRecords
                  }}
                />
              </UIPaperFullWidth>
            </Box>
            <Box height="1em" />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </UIAnimateAutoHeight>
  );
};

export default RenewalDataTableClaims;
