import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  makeStyles
} from '@material-ui/core';
import UIDataTableHeadCellCheckbox from '../UI/DataTableHeadCellCheckbox';
import UIDataTableHeadCellRecordField from '../UI/DataTableHeadCellRecordField';
import UIDataTableHeadCellOpenRecord from '../UI/DataTableHeadCellOpenRecord';
import UIDataTableHeadCellIsRecordEdited from '../UI/DataTableHeadCellIsRecordEdited';
import UIDataTableHeadCellHasMessage from '../UI/DataTableHeadCellHasMessage';
//import UIDataTableHeadCellAssetDoc from '../UI/DataTableHeadCellAssetDoc';
import UIDataTableFullWidth from '../UI/DataTableFullWidth';
import UIPaperFullWidth from '../UI/PaperFullWidth';
import UIDataTableAppBar from '../UI/DataTableAppBar';
import UIDataTablePagination from '../UI/DataTablePagination';
import RenewalDataTablePropertiesFilterRow from '../Renewal/DataTablePropertiesFilterRow';
import RenewalDataTablePropertiesDataRow from '../Renewal/DataTablePropertiesDataRow';
import UIDataTableCustomFiltersContainer from '../UI/DataTableCustomFiltersContainer';
import { connect } from 'react-redux';
import moment from 'moment';

const sort = (records, target, order) => {
  const sortedRecords = records.sort((a, b) => {
    try {
      let dateA = moment(
        a.alx_fields[target].oldFieldValue
          ? a.alx_fields[target].oldFieldValue
          : a.alx_fields[target].fieldValue
      );

      let dateB = moment(
        b.alx_fields[target].oldFieldValue
          ? b.alx_fields[target].oldFieldValue
          : b.alx_fields[target].fieldValue
      );

      if (dateA !== 'Invalid date' && dateB !== 'Invalid date') {
        if (dateA.valueOf() < dateB.valueOf()) {
          if (order === 'Asc') {
            return 1;
          } else if (order === 'Desc') {
            return -1;
          }
        } else if (dateA.valueOf() > dateB.valueOf()) {
          if (order === 'Asc') {
            return -1;
          } else if (order === 'Desc') {
            return 1;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } catch (err) {
      //console.log("🚀 ~ file: DataTableProperties.jsx ~ line 85 ~ sortedRecords ~ err", err)
      return 0;
    }
  });
  return sortedRecords;
};

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    height: '52em'
  }
});

const RenewalDataTableProperties = ({
  actionChangePropertyRecordFieldValue,
  actionResetPropertyRecordAndItsUnitRecords,
  actionSetCurrentPropertiesPage,
  actionSetDataTablesFilterValue,
  actionSetNumOfPropertiesToShowPerPage,
  actionSetOpenedProperty,
  actionSetSelectedProperties,
  actionToggleOpenedProperty,
  actionToggleSelectedProperty,
  actionSetDataTablesCheckedFilterValue,
  stateChangedRecordIndexes,
  stateCompositePropertyRecordsToShow,
  stateCurrentPropertiesPage,
  stateDataTablesFilter,
  stateEditModesBasedOnState,
  stateIsPropertyOpen,
  stateNumOfPropertyRecordsPerPage,
  stateNumOfTotalPropertyRecords,
  statePropertyDataTableAlxColumns,
  statePropertyDataTableCoordinatorColumns,
  stateSelectedProperties,
  stateUpdatedRenewalRecords,
  stateRenewalUser,
  sortBy,
  alxFilters,
  role
}) => {
  const classes = useStyles();

  const [openFilters, setOpenFilters] = useState(false);
  const [checkedValues, setCheckedValues] = useState({
    Units: [],
    TotalClaims: [],
    OccupiedVacant: [],
    Occupancy: [],
    Peril: [],
    CurrentState: [],
    Claimslast12Months: []
  });

  const [records, setRecords] = useState(
    sort(stateCompositePropertyRecordsToShow, sortBy.target, sortBy.order)
  );
  //console.log("🚀 ~ file: DataTableProperties.jsx ~ line 90 ~ records", records)

  // useEffect(() => {
  // console.log("🚀 ~ file: DataTableProperties.jsx ~ line 106 ~ statePropertyDataTableCoordinatorColumns", statePropertyDataTableCoordinatorColumns)
  //setRecords(sort(stateCompositePropertyRecordsToShow, sortBy.target, sortBy.order))
  //}, [statePropertyDataTableCoordinatorColumns])

  useEffect(() => {
    //console.log("🚀 ~ file: DataTableProperties.jsx ~ line 106 ~ stateCompositePropertyRecordsToShow", stateCompositePropertyRecordsToShow)
    //setRecords(stateCompositePropertyRecordsToShow)
    setRecords(
      sort(stateCompositePropertyRecordsToShow, sortBy.target, sortBy.order)
    );
  }, [stateCompositePropertyRecordsToShow]);

  useEffect(() => {
    //console.log("🚀 ~ file: DataTableProperties.jsx ~ line 111 ~ sortBy", sortBy)
    setRecords(sort(records, sortBy.target, sortBy.order));
  }, [sortBy]);

  useEffect(() => {
    if (!Object.keys(alxFilters).length) {
      setCheckedValues({
        Units: [],
        TotalClaims: [],
        OccupiedVacant: [],
        Occupancy: [],
        Peril: [],
        CurrentState: [],
        Claimslast12Months: []
      });
    }
  }, [alxFilters]);

  const handleToggleAllSelectedProperty = useCallback(() => {
    actionSetSelectedProperties({
      propertiesToSelect:
        stateChangedRecordIndexes.length === stateSelectedProperties.length
          ? []
          : stateChangedRecordIndexes
    });
  }, [
    actionSetSelectedProperties,
    stateChangedRecordIndexes,
    stateSelectedProperties
  ]);

  return (
    <React.Fragment>
      <UIDataTableAppBar
        {...{
          bEnableFeatureHideDataTable: false,
          bShowDataTable: true,
          handleToggleShowDataTable: () => {},
          iconName: 'HomeIcon',
          numOfRecords: stateNumOfTotalPropertyRecords,
          titleAppBar: 'PROPERTIES',
          titleTooltip: 'Number of Properties',
          filterTooltip: 'Additional Filters',
          stateIsPropertyOpen,
          openFilters,
          setOpenFilters
        }}
      />
      {stateIsPropertyOpen ? null : openFilters ? (
        <UIDataTableCustomFiltersContainer
          {...{
            stateUpdatedRenewalRecords,
            stateDataTablesFilter,
            actionSetCurrentPropertiesPage,
            actionSetDataTablesFilterValue,
            checkedValues,
            setCheckedValues,
            stateRenewalUser
          }}
        />
      ) : null}
      <Box
        className="property-table-box"
        height={stateIsPropertyOpen ? '' : '55em'}
        display="flex"
      >
        <UIPaperFullWidth>
          <UIDataTablePagination
            {...{
              bShowPagination: !stateIsPropertyOpen,
              currentPage: stateCurrentPropertiesPage,
              handleSetCurrentPage: actionSetCurrentPropertiesPage,
              handleSetNumOfRecordsToShowPerPage: actionSetNumOfPropertiesToShowPerPage,
              numOfRecordsPerPage: stateNumOfPropertyRecordsPerPage,
              numOfRowsPerPageOptions: [5, 10, 25, 50],
              numOfTotalRecords: stateNumOfTotalPropertyRecords
            }}
          />
          <TableContainer
            className={stateIsPropertyOpen ? 'test-class' : classes.container}
          >
            <UIDataTableFullWidth stickyHeader>
              <TableHead>
                <TableRow>
                  <UIDataTableHeadCellOpenRecord />
                  {stateIsPropertyOpen ? null : (
                    <UIDataTableHeadCellCheckbox
                      isIndeterminate={
                        0 < stateSelectedProperties.length &&
                        stateSelectedProperties.length !==
                          stateChangedRecordIndexes.length
                      }
                      isChecked={
                        stateSelectedProperties.length ===
                        stateChangedRecordIndexes.length
                      }
                      isVisible={0 < stateChangedRecordIndexes.length}
                      onClick={handleToggleAllSelectedProperty}
                    />
                  )}
                  <UIDataTableHeadCellHasMessage />
                  <UIDataTableHeadCellIsRecordEdited />
                  <UIDataTableHeadCellRecordField
                    label={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_current_state'
                      ]['columnLabel']
                    }
                  />
                  <UIDataTableHeadCellRecordField
                    background="quinary"
                    label={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_next_state'
                      ]['columnLabel']
                    }
                  />
                  <UIDataTableHeadCellRecordField
                    background="secondary"
                    label={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_units'
                      ]['columnLabel']
                    }
                    columnWidth={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_units'
                      ]['columnWidth']
                    }
                  />
                  <UIDataTableHeadCellRecordField
                    background="secondary"
                    label={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_claims'
                      ]['columnLabel']
                    }
                    columnWidth={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_claims'
                      ]['columnWidth']
                    }
                  />
                  <UIDataTableHeadCellRecordField
                    background="secondary"
                    label={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_claims_12_mos'
                      ]['columnLabel']
                    }
                    columnWidth={
                      statePropertyDataTableCoordinatorColumns[
                        'coordinator_field_number_of_claims_12_mos'
                      ]['columnWidth']
                    }
                  />
                  {/* S.P - Where header first row get mapped from insurer Name onwards */}
                  {statePropertyDataTableAlxColumns.map(
                    (
                      {
                        columnLabel,
                        columnVisible,
                        columnWidth,
                        fieldName,
                        orderBy
                      },
                      i
                    ) => {
                      return (
                        <React.Fragment key={`unlocked-column-th-${i}`}>
                          {columnVisible ? (
                            <UIDataTableHeadCellRecordField
                              label={columnLabel}
                              columnWidth={columnWidth}
                              fieldName={fieldName}
                              orderBy={orderBy}
                            />
                          ) : null}
                        </React.Fragment>
                      );
                    }
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {stateIsPropertyOpen ? null : (
                  <RenewalDataTablePropertiesFilterRow
                    {...{
                      actionSetCurrentPropertiesPage,
                      actionSetDataTablesFilterValue,
                      actionSetDataTablesCheckedFilterValue,
                      stateDataTablesFilter,
                      statePropertyDataTableAlxColumns,
                      stateIsPropertyOpen,
                      statePropertyDataTableCoordinatorColumns,
                      stateUpdatedRenewalRecords,
                      checkedValues,
                      setCheckedValues
                    }}
                  />
                )}
                {records.map((compositeRecord, index) => {
                  return (
                    <RenewalDataTablePropertiesDataRow
                      key={`table-row-${index}`}
                      style={{ height: 0, overflow: 'hidden' }}
                      {...{
                        actionChangePropertyRecordFieldValue,
                        actionResetPropertyRecordAndItsUnitRecords,
                        actionSetOpenedProperty,
                        actionToggleOpenedProperty,
                        actionToggleSelectedProperty,
                        compositeRecord,
                        stateChangedRecordIndexes,
                        stateEditModesBasedOnState,
                        stateIsPropertyOpen,
                        statePropertyDataTableAlxColumns,
                        stateSelectedProperties,
                        role
                      }}
                    />
                  );
                })}
              </TableBody>
            </UIDataTableFullWidth>
          </TableContainer>
          <UIDataTablePagination
            {...{
              bShowPagination: !stateIsPropertyOpen,
              currentPage: stateCurrentPropertiesPage,
              handleSetCurrentPage: actionSetCurrentPropertiesPage,
              handleSetNumOfRecordsToShowPerPage: actionSetNumOfPropertiesToShowPerPage,
              numOfRecordsPerPage: stateNumOfPropertyRecordsPerPage,
              numOfRowsPerPageOptions: [5, 10, 25, 50],
              numOfTotalRecords: stateNumOfTotalPropertyRecords
            }}
          />
        </UIPaperFullWidth>
      </Box>
      <Box height={stateIsPropertyOpen ? '4em' : '7em'} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  sortBy: state.renewal.records.sortBy,
  alxFilters: state.renewal.dataTable.property.filter.property,
  role: state.renewal.user.requester
});

export default connect(mapStateToProps)(RenewalDataTableProperties);
