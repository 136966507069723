import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import apiServices from '../../services/api';
import { Button, Typography } from '@material-ui/core';
import _ from 'lodash';

// If field_renewal_status === 'Terms accepted by Handler' OR 'Sent to market by Handler'
// If field_p_certificate AND field_p_invoice
// Then Option will be Re-Generate Invoice and Certificate
// If field_p_certificate AND field_p_invoice are empty
// Then option will be Documents not received from Asset attempt to re fetch from asset.
// Renewal in Progress

const UIDataTableBodyCellAssetDoc = ({
  propertyID,
  records,
  encryptedResource
  //compositeRecord
}) => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [userFeedback, setUserFeedback] = useState(false);
  const [time, setTime] = useState(60);
  const [startTimer, setStartTimer] = useState(false);

  const updateTimer = () => {
    if (time > 0) {
      setTime(prev => {
        if (prev === 0) return 0;
        return prev - 1;
      });
    }
  };

  useEffect(() => {
    let timer;
    if (startTimer) {
      timer = setInterval(updateTimer, 1000);
    }

    if (time === 0) clearInterval(timer);

    return () => {
      clearInterval(timer);
    };
  }, [startTimer, time]);

  // Flatten the dataset, we only need a list of updated property records.
  const propertyRecords = records.reduce((prev, curr) => {
    return [...prev, curr['property']['alx_fields']];
  }, []);

  // Find the updated property
  const propertyUpdated = records.find(
    record =>
      _.get(record, ['property', 'alx_fields', 'field_property_id']) ===
      propertyID
  );

  // Find the property record for this row, based on the property ID passed in to the props.
  const thisRecord = propertyRecords.find(
    ({ field_property_id: id }) => id === propertyID
  );

  const updateTarget = data => {
    const id = _.get(propertyUpdated, [
      'property',
      'coordinator_fields',
      'coordinator_field_alx_target_id'
    ]);
    apiServices.updateTarget(encryptedResource, id, data);

    // apiServices.submitRecordChangesForEncryptedResource(encryptedResource, [
    //     {
    //         property: {
    //             alx_fields: {
    //                 ...data
    //             },
    //             coordinator_fields: {
    //                 ..._.get(propertyUpdated, ['property', 'coordinator_fields'])
    //             },
    //             custom_fields: {}
    //         },
    //         units: [{
    //             alx_fields: {},
    //             coordinator_fields: {
    //                 ..._.get(propertyUpdated, ['units', 0, 'coordinator_fields'])
    //             },
    //             custom_fields: {}
    //         }]
    //     }
    // ])
  };

  const handleRefetchDocs = () => {
    updateTarget({
      field_refetch_documents_from_asset: true
    });

    setDisabledBtn(true);
    setUserFeedback(true);
    setStartTimer(true);
  };

  const handleRegenerateDocs = () => {
    updateTarget({
      field_regenerate_documents_from_asset: true
    });

    setDisabledBtn(true);
    setUserFeedback(true);
    setStartTimer(true);
  };

  const handleRetryRenewOnAsset = () => {
    updateTarget({
      field_retry_renew_on_asset: true
    });

    setDisabledBtn(true);
    setUserFeedback(true);
    setStartTimer(true);
  };

  const handleRestartComparisonProcess = () => {
    updateTarget({
      field_restart_comparison_process: true,
      field_asset_document_process_status: ''
    });

    setDisabledBtn(true);
    setUserFeedback(true);
    setStartTimer(true);
  };

  const renderOption = (record = []) => {
    if (Array.isArray(record)) {
      if (!record.length) {
        return (
          <div>
            <Typography>Unable to get status at this time.</Typography>
          </div>
        );
      }
    }

    const certificateExists = !!record['field_p_certificate'];
    const invoiceExists = !!record['field_p_invoice'];
    const status = record['field_renewal_status'];
    const statusPass =
      status === ('Terms accepted by Handler' || 'Sent to market by Handler');
    const assetProcessStatus = record['field_asset_document_process_status'];
    const assetStatus = record['field_asset_status'];

    //Option 3 - Comparison process failed, run comparison process again.
    // This option first as docs may be correct but this failed so needs to show first.
    // Needs to reset comparison_api_process field to it's default value.
    // use new field not yet created

    if (assetStatus && assetStatus.toLowerCase() !== 'OK'.toLowerCase()) {
      return (
        <div>
          <div className="asset-card-txt">
            <Typography>{`Message from Asset: ${assetStatus}`}</Typography>
          </div>
          <div className="asset-card-btn">
            <Button
              disabled={disabledBtn}
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRetryRenewOnAsset}
            >
              {disabledBtn ? 'Retrying in Progress' : 'Retry to renew on Asset'}
            </Button>
          </div>
          <div className="asset-card-feedback">
            <Typography>
              {userFeedback &&
                `Retrying to Renew property on asset, try to refresh this page in ${time} seconds if this error persists then contact an Asset Admin team member.`}
            </Typography>
          </div>
        </div>
      );
    }

    if (assetProcessStatus === 'Comparison Failed' && statusPass) {
      return (
        <div>
          <div className="asset-card-txt">
            <Typography>Comparison Process failed</Typography>
          </div>
          <div className="asset-card-btn">
            <Button
              disabled={disabledBtn}
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRestartComparisonProcess}
            >
              {disabledBtn ? 'Process Re-Started' : 'Re-start process'}
            </Button>
          </div>
          <div className="asset-card-feedback">
            <Typography>
              {userFeedback && 'Re-starting the comparison Process.'}
            </Typography>
          </div>
        </div>
      );
    }

    //Option 1 - Documents not received from Asset.
    if (!certificateExists && !invoiceExists && statusPass) {
      return (
        <div>
          <div className="asset-card-txt">
            <Typography>Doc&apos;s not received from Asset</Typography>
          </div>
          <div className="asset-card-btn">
            <Button
              disabled={disabledBtn}
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRefetchDocs}
            >
              {disabledBtn
                ? 'Already Re-fetched, Refresh page to try again.'
                : 'Re-Fetch Documents'}
            </Button>
          </div>
          <div className="asset-card-feedback">
            <Typography>
              {userFeedback &&
                `Trying to re-refetch documents in Asset, try to refresh this page in ${time} seconds if this error persists then contact an Asset Admin team member.`}
            </Typography>
          </div>
        </div>
      );
    }

    //Option 2 - Documents received from Asset and changes made re-fetch documents.
    if (certificateExists && invoiceExists && statusPass) {
      return (
        <div>
          <div className="asset-card-txt">
            <Typography>
              Doc&apos;s received from Asset refetch if changes made.
            </Typography>
          </div>
          <div className="asset-card-btn">
            <Button
              disabled={disabledBtn}
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRegenerateDocs}
            >
              {disabledBtn
                ? 'Already Re-fetched, Refresh page to try again.'
                : 'Re-Fetch Documents from Asset'}
            </Button>
          </div>
          <div className="asset-card-feedback">
            <Typography>
              {userFeedback &&
                `Trying to re-generate documents in Asset, try to refresh this page in ${time} seconds if this error persists then contact an Asset Admin team member.`}
            </Typography>
          </div>
        </div>
      );
    }

    // Option 4 - The renewal is still in the review stage
    return (
      <div>
        <div className="asset-card-txt">
          <Typography>Review in Progress.</Typography>
        </div>
      </div>
    );
  };

  return <div>{renderOption(thisRecord)}</div>;
};

const mapStateToProps = state => ({
  records: state.renewal.records.updated,
  encryptedResource: state.renewal.access.encryptedResource
});

export default connect(mapStateToProps)(UIDataTableBodyCellAssetDoc);
