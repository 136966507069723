import { CHANGE_UNIT_RECORD_FIELD_VALUE } from '../constants';

export const actionChangeUnitRecordFieldValue = ({
  fieldGroup,
  fieldName,
  fieldValue,
  propertyRecordIndex,
  unitRecordIndex
}) => {
  return async dispatch => {
    dispatch({
      type: CHANGE_UNIT_RECORD_FIELD_VALUE,
      fieldGroup,
      fieldName,
      fieldValue,
      propertyRecordIndex,
      unitRecordIndex
    });
  };
};

export default actionChangeUnitRecordFieldValue;
