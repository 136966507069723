import { SET_NUMBER_OF_CLAIMS_TO_SHOW_PER_PAGE } from '../constants';

export const actionSetNumOfClaimsToShowPerPage = numOfRecordsPerPage => {
  return async dispatch => {
    dispatch({
      type: SET_NUMBER_OF_CLAIMS_TO_SHOW_PER_PAGE,
      numOfRecordsPerPage
    });
  };
};

export default actionSetNumOfClaimsToShowPerPage;
