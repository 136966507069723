import React from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import {
  AlternateEmail as AlternateEmailIcon,
  Face as FaceIcon,
  Person as PersonIcon,
  FiberManualRecord as FiberManualRecordIcon,
  LibraryBooks as LibraryBooksIcon
} from '@material-ui/icons';
import logo from '../../assets/lockton-logo.png';
import UIFlexboxCenterPositioner from '../UI/FlexboxCenterPositioner';

export const RenewalUserInfoBar = ({
  requestType,
  requesterType,
  requesterId,
  requesterName,
  requesterEmail
}) => {
  return (
    <Box display="flex">
      <Typography component="div">
        <UIFlexboxCenterPositioner>
          <Chip
            color="primary"
            label={requestType}
            icon={<LibraryBooksIcon />}
            style={{ marginLeft: '1em' }}
          />
        </UIFlexboxCenterPositioner>
      </Typography>
      <Typography component="div">
        <UIFlexboxCenterPositioner>
          <Chip
            color="primary"
            label={requesterType}
            icon={<PersonIcon />}
            style={{ marginLeft: '1em' }}
          />
        </UIFlexboxCenterPositioner>
      </Typography>
      <Typography component="div">
        <UIFlexboxCenterPositioner>
          <Chip
            color="secondary"
            label={requesterId}
            icon={<FiberManualRecordIcon />}
            style={{ marginLeft: '1em' }}
          />
        </UIFlexboxCenterPositioner>
      </Typography>
      <Typography component="div">
        <UIFlexboxCenterPositioner>
          <Chip
            color="secondary"
            label={requesterName}
            icon={<FaceIcon />}
            style={{ marginLeft: '1em' }}
          />
        </UIFlexboxCenterPositioner>
      </Typography>
      <Typography component="div">
        <UIFlexboxCenterPositioner>
          <Chip
            color="secondary"
            label={requesterEmail}
            icon={<AlternateEmailIcon />}
            style={{ marginLeft: '1em' }}
          />
        </UIFlexboxCenterPositioner>
      </Typography>
      <Typography component="div" style={{ flexGrow: 1 }}></Typography>
      <Typography component="div">
        <img src={logo} alt="Logo" style={{ height: '5vh' }} />
      </Typography>
    </Box>
  );
};

export default RenewalUserInfoBar;
