class CSS {
  splitSizeToNumericAndDimension(sizeString) {
    const dimension = sizeString.replace(/^\d+/g, '');

    return {
      value: parseFloat(sizeString.replace(dimension, '')),
      dimension: dimension
    };
  }
}

export default new CSS();
