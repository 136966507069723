import React, { useState, useEffect } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import styled from 'styled-components';
import config from '../../config';

const StyledTextAreaField = styled(TextareaAutosize)`
  border-radius: 0;
  border: 0;
  background: ${config.styles.colors.inputHighlight} !important;
`;

export const DataTableBodyCellRecordFieldEditableTextArea = ({
  fieldValue,
  handleFieldChange
}) => {
  const [value, setValue] = useState('');
  const [timer, setTimer] = useState(null);

  const timeoutWrapper = newValue => {
    return () => {
      handleFieldChange(newValue);
    };
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  return (
    <StyledTextAreaField
      value={value}
      variant="outlined"
      style={{
        padding: '3px',
        background: '#fff'
      }}
      onChange={event => {
        clearTimeout(timer);
        setValue(event.target.value);
        setTimer(setTimeout(timeoutWrapper(event.target.value), 300));
      }}
    />
  );
};

export default DataTableBodyCellRecordFieldEditableTextArea;
