import _ from 'lodash';
import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { createSelector } from 'reselect';
import filterRecords from '../../helpers/filterRecords';
import createCompositeRecord from '../../helpers/createCompositeRecord';
import paginationHelper from '../../helpers/paginationHelper';
import recordCurrentState from '../../helpers/recordCurrentState';
import recordNextState from '../../helpers/recordNextState';
import recordsUpdated from './recordsUpdated.selector';
import recordsChanged from './recordsChanged.selector';
import float from './float.selector';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';
import filterSelectorFactory from './dataTable/filterFactory.selector';

export const selectorCompositePropertyRecordsToShow = createSelector(
  [
    paginationSelectorFactory('property', 'currentPage'),
    paginationSelectorFactory('property', 'numOfRecordsPerPage'),
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated,
    recordsChanged,
    float,
    filterSelectorFactory('property'),
    columnAndRecordConfigsSelectorFactory('fieldTypesNumericalValues'),
    columnAndRecordConfigsSelectorFactory('propertyFieldConfigsBasedOnState'),
    columnAndRecordConfigsSelectorFactory('propertyColumnConfigsBasedOnState'),
  ],
  (
    currentPropertiesPage,
    numOfPropertyRecordsPerPage,
    openedPropertyIndex,
    recordsUpdated,
    recordsChanged,
    float,
    dataTableFilter,
    fieldTypesNumericalValues,
    propertyFieldConfigsBasedOnState,
    propertyColumnConfigsBasedOnState,
  ) => {
    if (recordsUpdated.length === 0) {
      return [];
    }

    const numericsToFieldTypes = _.invert(fieldTypesNumericalValues);

    if (Number.isInteger(openedPropertyIndex)) {
      const record = recordsUpdated[openedPropertyIndex][FIELD_CATEGORY_PROPERTY]

      const recordChanges =
        recordsChanged[openedPropertyIndex][FIELD_CATEGORY_PROPERTY];
      return [
        createCompositeRecord({
          currentState: recordCurrentState(record, recordChanges),
          nextState: recordNextState(record, recordChanges),
          record,
          recordChanges,
          columnConfigs: propertyColumnConfigsBasedOnState,
          numericsToFieldTypes,
          fieldConfigsBasedOnState: propertyFieldConfigsBasedOnState
        })
      ];
    }

    const filteredRecordIndexes = filterRecords({
      fieldCategory: FIELD_CATEGORY_PROPERTY,
      dataTableFilter,
      recordsUpdated,
      recordsChanged
    });

    const { startAt, endAt } = paginationHelper({
      currentPage: currentPropertiesPage,
      numOfRecordsPerPage: numOfPropertyRecordsPerPage,
      totalNumOfRecords: filteredRecordIndexes.length
    });

    const toShow = [];

    for (let i = startAt, iMax = endAt; i < iMax; i++) {
      const record =
        recordsUpdated[filteredRecordIndexes[i]][FIELD_CATEGORY_PROPERTY];

      const recordChanges =
        recordsChanged[filteredRecordIndexes[i]][FIELD_CATEGORY_PROPERTY];
      toShow.push(
        createCompositeRecord({
          currentState: recordCurrentState(record, recordChanges),
          nextState: recordNextState(record, recordChanges),
          record,
          recordChanges,
          columnConfigs: propertyColumnConfigsBasedOnState,
          numericsToFieldTypes,
          fieldConfigsBasedOnState: propertyFieldConfigsBasedOnState
        })
      );
    }
    return toShow;
  }
);

export default selectorCompositePropertyRecordsToShow;
