import React from 'react';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import UIDataTableBodyCell from './DataTableBodyCell';
import UIFlexboxCenterPositioner from './FlexboxCenterPositioner';
import { Tooltip } from '@material-ui/core';

export const UIDataTableBodyCellIsRecordEdited = ({
  showIcon,
  handleClick
}) => {
  return (
    <UIDataTableBodyCell width="xs" padding="none">
      {showIcon ? (
        <UIFlexboxCenterPositioner
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          <Tooltip title="This property has been edited.">
            <ErrorOutlineIcon />
          </Tooltip>
        </UIFlexboxCenterPositioner>
      ) : null}
    </UIDataTableBodyCell>
  );
};

export default UIDataTableBodyCellIsRecordEdited;
