import { FIELD_GROUP_COORDINATOR_FIELDS } from '../consts';

export const recordNexState = (record, recordChanges) => {
  return (
    recordChanges[FIELD_GROUP_COORDINATOR_FIELDS][
    'coordinator_field_next_state'
    ] || record[FIELD_GROUP_COORDINATOR_FIELDS]['coordinator_field_next_state']
  );
};

export default recordNexState;
