import {
  RESET_ENCRYPTED_RESOURCE,
  SET_ENCRYPTED_RESOURCE
} from '../../constants';

const initialState = {
  encryptedResource: null
};

export const renewalAccessReducer = (
  state = initialState,
  { type, encryptedResource }
) => {
  switch (type) {
    case RESET_ENCRYPTED_RESOURCE:
      return {
        ...initialState
      };

    case SET_ENCRYPTED_RESOURCE:
      return {
        encryptedResource: encryptedResource
      };
    default:
      return state;
  }
};

export default renewalAccessReducer;
