import {
  CHANGE_PROPERTY_RECORD_FIELD_VALUE,
  CHANGE_UNIT_RECORD_FIELD_VALUE,
  REMOVE_RENEWAL_RECORDS,
  RESET_RENEWAL_RECORD,
  RESET_RENEWAL_RECORDS,
  SET_RENEWAL_RECORDS,
  SET_FLOAT_INCREASE,
} from '../../constants';
import floatIncrease from '../../../helpers/floatIncrease';
import assetPremiumCalc from '../../../helpers/assetPremiumCalc'
import services from '../../../services';

const initialState = {
  sortBy: { target: "field_p_inception_date", order: "Asc" }, // target: targetField, order: asc OR Desc
  updated: [],
  changed: [],
};

export const renewalRecordsReducer = (
  state = initialState,
  {
    type,
    fieldGroup,
    fieldName,
    fieldValue,
    propertyRecordIndex,
    renewalRecords,
    renewalRecordsToRemove,
    unitRecordIndex,
    stateFilteredIndexes,
    stateFloat,
    target,
    order
  }
) => {
  switch (type) {
    case SET_RENEWAL_RECORDS:
      return {
        ...state,
        ...{
          updated: renewalRecords,
          changed: services.renewalRecords.generateEmptyChangedRecords(
            renewalRecords
          ),
        },
      };

    case REMOVE_RENEWAL_RECORDS:
      return {
        ...state,
        ...{
          updated: state.updated.filter((record, index) => {
            return !renewalRecordsToRemove.includes(index);
          }),
          changed: state.changed
            .filter((record, index) => {
              return !renewalRecordsToRemove.includes(index);
            })
            .map((record, index) => {
              record['property']['meta']['index'] = index;

              return record;
            }),
        },
      };

    case RESET_RENEWAL_RECORDS:
      return {
        ...initialState,
      };

    case RESET_RENEWAL_RECORD: {
      const emptyRecord = {
        property: services.renewalRecords.resetChangedRecord(
          state['changed'][propertyRecordIndex]['property']
        ),
        units: state['changed'][propertyRecordIndex]['units'].map(
          services.renewalRecords.resetChangedRecord
        ),
        claims: state['changed'][propertyRecordIndex]['claims'].map(
          services.renewalRecords.resetChangedRecord
        ),
      };

      const newChangedWithEmpty = [
        ...state['changed'].slice(0, propertyRecordIndex),
        emptyRecord,
        ...state['changed'].slice(propertyRecordIndex + 1),
      ];

      return {
        ...state,
        ...{
          changed: newChangedWithEmpty,
        },
      };
    }

    case SET_FLOAT_INCREASE: {
      return {
        ...state,
        changed: state.changed.map((record, index) => {
          if (stateFilteredIndexes.includes(index) && state.updated[index].property.alx_fields.field_renewal_status === "With handler for agreed scheme updates" && !Object.prototype.hasOwnProperty.call(state.changed[index].units[0], 'pre_float_alx_fields') && !Object.prototype.hasOwnProperty.call(state.changed[index].property.meta, 'rateUpdated')) {
            return {
              ...record,
              property: {
                ...record.property,
                meta: {
                  ...record.property.meta,
                  bUpdated: true
                }
              },
              units: state.updated[index].units.map((unit, updateIndex) => {
                return {
                  ...record.units[updateIndex],
                  meta: {
                    ...record.units[updateIndex].meta,
                    bUpdated: true,
                    updatedAt: new Date().getTime()
                  },
                  pre_float_alx_fields: {
                    field_u_buildings_rate: record.units[updateIndex].alx_fields.field_u_buildings_rate || unit.alx_fields.field_u_buildings_rate,
                    field_u_contents_rate: record.units[updateIndex].alx_fields.field_u_contents_rate || unit.alx_fields.field_u_contents_rate,
                    field_u_rent_rate: record.units[updateIndex].alx_fields.field_u_rent_rate || unit.alx_fields.field_u_rent_rate,
                    field_u_service_charge_rate: record.units[updateIndex].alx_fields.field_u_service_charge_rate || unit.alx_fields.field_u_service_charge_rate,
                    field_u_property_owners_liability_rate: record.units[updateIndex].alx_fields.field_u_property_owners_liability_rate || unit.alx_fields.field_u_property_owners_liability_rate,
                    field_u_total_asset_premium_ex_tax: record.units[updateIndex].alx_fields.field_u_total_asset_premium_ex_tax || unit.alx_fields.field_u_total_asset_premium_ex_tax
                  },
                  alx_fields: {
                    ...unit.alx_fields,
                    ...record.units[updateIndex].alx_fields,
                    field_u_total_asset_premium_ex_tax: floatIncrease(
                      stateFloat,
                      assetPremiumCalc(
                        unit.alx_fields.field_u_building_declared_value,
                        record.units[updateIndex].alx_fields.field_u_buildings_rate || unit.alx_fields.field_u_buildings_rate,
                        unit.alx_fields.field_u_contents_sum_insured,
                        record.units[updateIndex].alx_fields.field_u_contents_rate || unit.alx_fields.field_u_contents_rate,
                        unit.alx_fields.field_u_rent_sum_insured,
                        record.units[updateIndex].alx_fields.field_u_rent_rate || unit.alx_fields.field_u_rent_rate,
                        unit.alx_fields.field_u_service_charge_sum_insured,
                        record.units[updateIndex].alx_fields.field_u_service_charge_rate || unit.alx_fields.field_u_service_charge_rate,
                        unit.alx_fields.field_u_property_owners_sum_insured,
                        record.units[updateIndex].alx_fields.field_u_property_owners_liability_rate || unit.alx_fields.field_u_property_owners_liability_rate,
                      )
                    ).toFixed(4),
                    field_u_buildings_rate: floatIncrease(
                      stateFloat,
                      record.units[updateIndex].alx_fields.field_u_buildings_rate || unit.alx_fields.field_u_buildings_rate
                    ),
                    field_u_contents_rate: floatIncrease(
                      stateFloat,
                      record.units[updateIndex].alx_fields.field_u_contents_rate || unit.alx_fields.field_u_contents_rate
                    ),
                    field_u_rent_rate: floatIncrease(
                      stateFloat,
                      record.units[updateIndex].alx_fields.field_u_rent_rate || unit.alx_fields.field_u_rent_rate
                    ),
                    field_u_service_charge_rate: floatIncrease(
                      stateFloat,
                      record.units[updateIndex].alx_fields.field_u_service_charge_rate || unit.alx_fields.field_u_service_charge_rate
                    ),
                    field_u_property_owners_liability_rate: floatIncrease(
                      stateFloat,
                      record.units[updateIndex].alx_fields.field_u_property_owners_liability_rate || unit.alx_fields.field_u_property_owners_liability_rate
                    ),
                  }
                }
              })
            };
          }
          return record;
        }),
      };
    }

    case CHANGE_PROPERTY_RECORD_FIELD_VALUE: {
      const changedRenewalRecordOnPropertyChange = JSON.parse(
        JSON.stringify(state['changed'][propertyRecordIndex])
      );
      changedRenewalRecordOnPropertyChange['property']['meta'][
        'bUpdated'
      ] = true;
      changedRenewalRecordOnPropertyChange['property']['meta'][
        'updatedAt'
      ] = new Date().getTime();
      changedRenewalRecordOnPropertyChange['property'][fieldGroup][
        fieldName
      ] = fieldValue;

      const newChangedOnPropertyChange = [
        ...state['changed'].slice(0, propertyRecordIndex),
        changedRenewalRecordOnPropertyChange,
        ...state['changed'].slice(propertyRecordIndex + 1),
      ];

      return {
        ...state,
        ...{
          changed: newChangedOnPropertyChange,
        },
      };
    }

    case CHANGE_UNIT_RECORD_FIELD_VALUE: {
      const changedRenewalRecordOnUnitChange = JSON.parse(
        JSON.stringify(state['changed'][propertyRecordIndex])
      );
      changedRenewalRecordOnUnitChange['property']['meta']['bUpdated'] = true;
      if (fieldName.includes('rate')) {
        changedRenewalRecordOnUnitChange['property']['meta'].rateUpdated = true
      }
      changedRenewalRecordOnUnitChange['units'][unitRecordIndex]['meta'][
        'bUpdated'
      ] = true;
      changedRenewalRecordOnUnitChange['units'][unitRecordIndex]['meta'][
        'updatedAt'
      ] = new Date().getTime();
      changedRenewalRecordOnUnitChange['units'][unitRecordIndex][fieldGroup][
        fieldName
      ] = fieldValue;

      const newChangedOnUnitChange = [
        ...state['changed'].slice(0, propertyRecordIndex),
        changedRenewalRecordOnUnitChange,
        ...state['changed'].slice(propertyRecordIndex + 1),
      ];

      return {
        ...state,
        ...{
          changed: newChangedOnUnitChange,
        },
      };
    }
    case "UPDATE_ORDER_BY":
      return {
        ...state,
        sortBy: {
          target,
          order
        }
      }
    default:
      return state;
  }
};

export default renewalRecordsReducer;
