import _ from 'lodash';

const cached = {};

export const paginationFactory = (table, path) => {
  const key = `${table}_${JSON.stringify(path)}`;

  if (cached[key] === undefined) {
    cached[key] = state => {
      return _.get(state['renewal']['dataTable'][table]['pagination'], path);
    };
  }

  return cached[key];
};

export default paginationFactory;
