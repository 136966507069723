import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UIDataTableHeadCell from './DataTableHeadCell';
import { connect } from 'react-redux';

export const UIDataTableHeadCellRecordField = props => {
  const {
    label,
    background,
    columnWidth,
    fieldName,
    orderBy,
    updateOrderBy
  } = props;

  const [order, setOrder] = useState('Asc');

  const handleOnOrderClick = () => {
    const newOrder = order === 'Asc' ? 'Desc' : 'Asc';
    setOrder(newOrder);
    updateOrderBy(fieldName, newOrder);
  };

  return (
    <UIDataTableHeadCell
      width={columnWidth ? columnWidth : 's'}
      background={background}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '70%' }}>
          <Typography component="p">{label}</Typography>
        </div>
        {orderBy && (
          <div onClick={handleOnOrderClick} style={{ display: 'flex' }}>
            <IconButton>
              {order === 'Asc' ? (
                <ArrowUpwardIcon style={{ color: 'white', fontSize: 16 }} />
              ) : (
                <ArrowDownwardIcon style={{ color: 'white', fontSize: 16 }} />
              )}
            </IconButton>
          </div>
        )}
      </div>
    </UIDataTableHeadCell>
  );
};

const mapDispatchToProps = dispatch => ({
  updateOrderBy: (target, order) =>
    dispatch({ type: 'UPDATE_ORDER_BY', target, order })
});

export default connect(
  undefined,
  mapDispatchToProps
)(UIDataTableHeadCellRecordField);
