import React, { useState, useEffect } from 'react';
import config from '../../config';
import styled from 'styled-components';
import { FormControl, TextField } from '@material-ui/core';
import UIDataTableBodyCell from './DataTableBodyCell';
import { connect } from 'react-redux';

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .MuiFormControl-root {
    padding: 0 !important;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border: 0;
  }
  .MuiOutlinedInput-input {
    background: ${config.styles.colors.inputHighlight};
  }
`;

export const UIDataTableBodyCellFilter = props => {
  const { columnWidth, fieldValue, handleFieldChange, alxFilters } = props;

  const [value, setValue] = useState('');
  const [timer, setTimer] = useState(null);

  const timeoutWrapper = newValue => {
    return () => {
      handleFieldChange(newValue);
    };
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (!Object.keys(alxFilters).length) {
      setValue('');
    }
  }, [alxFilters]);

  return (
    <UIDataTableBodyCell width={columnWidth} padding="none">
      <StyledFormControl>
        <StyledTextField
          value={value}
          variant="outlined"
          style={{
            padding: '3px',
            background: '#fff'
          }}
          placeholder="Type text to filter by"
          onChange={event => {
            clearTimeout(timer);
            setValue(event.target.value);
            setTimer(setTimeout(timeoutWrapper(event.target.value), 300));
          }}
        />
      </StyledFormControl>
    </UIDataTableBodyCell>
  );
};

const mapStateToProps = state => ({
  alxFilters: state.renewal.dataTable.property
  //coordinatorFilters: state.renewal.dataTable.property.filter.property.coordinator_fields
});

export default connect(mapStateToProps)(UIDataTableBodyCellFilter);
