import {
  CLOSE_OPENED_PROPERTY,
  REMOVE_PROPERTIES_FROM_SELECTED,
  RESET_PROPERTIES_DATATABLE,
  SET_CURRENT_PROPERTIES_PAGE,
  SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE,
  SET_OPENED_PROPERTY,
  TOGGLE_OPENED_PROPERTY,
  TOGGLE_SELECTED_PROPERTY,
  SET_SELECTED_PROPERTIES
} from '../../../../constants';
import { CHANGE_UNIT_RECORD_FIELD_VALUE } from "../../../../constants";

const initialState = {
  currentPage: 0,
  numOfRecordsPerPage: 50,
  openedPropertyIndex: null,
  selectedProperties: []
};

const reduceSelectedProperties = (selecteds, toRemoves) => {
  const bRemoveAll = JSON.stringify(selecteds) === JSON.stringify(toRemoves);

  if (bRemoveAll) {
    return [];
  }

  let newSelecteds = JSON.parse(JSON.stringify(selecteds));

  toRemoves.forEach(toRemove => {
    const filteredSelecteds = [];

    newSelecteds.forEach(newSelected => {
      if (newSelected === toRemove) {
        return;
      }

      if (newSelected < toRemove) {
        return filteredSelecteds.push(newSelected);
      }

      filteredSelecteds.push(newSelected - 1);
    });

    newSelecteds = filteredSelecteds;
  });

  return newSelecteds;
};

export const renewalPropertyDataTableReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RESET_PROPERTIES_DATATABLE:
      return {
        ...initialState
      };

    case SET_CURRENT_PROPERTIES_PAGE:
      return {
        ...state,
        ...{
          currentPage: action.currentPage
        }
      };

    case SET_NUMBER_OF_PROPERTIES_TO_SHOW_PER_PAGE:
      return {
        ...state,
        ...{
          currentPage: 0,
          numOfRecordsPerPage: action.numOfRecordsPerPage
        }
      };

    case CHANGE_UNIT_RECORD_FIELD_VALUE: {
      const selectedProps = [...new Set([...state.selectedProperties, action.propertyRecordIndex])]; // @TODO ADDED CODE

      return {
        ...state,
        selectedProperties: selectedProps,
      }
    }

    case TOGGLE_SELECTED_PROPERTY: {
      const i = state.selectedProperties.indexOf(action.selectedPropertyIndex);
      const newSelectedProperties =
        i === -1
          ? [...state.selectedProperties, action.selectedPropertyIndex]
          : state.selectedProperties.filter(selectedProperty => {
            return selectedProperty !== action.selectedPropertyIndex;
          });
      return {
        ...state,
        ...{
          selectedProperties: newSelectedProperties.sort()
        }
      };
    }

    case SET_SELECTED_PROPERTIES: {
      return {
        ...state,
        selectedProperties: action.propertiesToSelect
      };
    }

    case REMOVE_PROPERTIES_FROM_SELECTED:
      return {
        ...state,
        ...{
          selectedProperties: reduceSelectedProperties(
            state.selectedProperties,
            action.propertiesToRemoveFromSelected
          )
        }
      };

    case TOGGLE_OPENED_PROPERTY:
      return {
        ...state,
        ...{
          openedPropertyIndex:
            state.openedPropertyIndex === action.openedPropertyIndex
              ? null
              : action.openedPropertyIndex
        }
      };

    case CLOSE_OPENED_PROPERTY:
      return {
        ...state,
        openedPropertyIndex: null
      };

    case SET_OPENED_PROPERTY:
      return {
        ...state,
        ...{
          openedPropertyIndex: action.openedPropertyIndex
        }
      };

    default:
      return state;
  }
};

export default renewalPropertyDataTableReducer;
