import { createSelector } from 'reselect';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorIsPropertyDetailsOpen = createSelector(
  [paginationSelectorFactory('property', 'openedPropertyIndex')],
  openedPropertyIndex => {
    return Number.isInteger(openedPropertyIndex);
  }
);

export default selectorIsPropertyDetailsOpen;
