// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const formFavIcon = document.querySelector("link[rel*='icon']")
if (formFavIcon) formFavIcon.remove()
const headEl = document.querySelector('head')
const linkEl = document.createElement('link')
linkEl.setAttribute('rel', 'icon')
linkEl.setAttribute('href', "https://global.lockton.com/img/lockton-favicon.png")
headEl.appendChild(linkEl)


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
