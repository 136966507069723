import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { createSelector } from 'reselect';
import filterRecords from '../../helpers/filterRecords';
import recordsUpdated from './recordsUpdated.selector';
import recordsChanged from './recordsChanged.selector';
import filterSelectorFactory from './dataTable/filterFactory.selector';

export const selectorFilteredPropertyIndexes = createSelector(
  [
    recordsUpdated,
    recordsChanged,
    filterSelectorFactory('property'),
  ],
  (
    recordsUpdated,
    recordsChanged,
    dataTableFilter,
  ) => {

    let filteredRecordIndexes = []

    filteredRecordIndexes = filterRecords({
      fieldCategory: FIELD_CATEGORY_PROPERTY,
      dataTableFilter,
      recordsUpdated,
      recordsChanged
    });

    return filteredRecordIndexes;
  }
);

export default selectorFilteredPropertyIndexes;
