import _ from 'lodash';
import { FIELD_CATEGORY_PROPERTY } from '../../consts';
import { createSelector } from 'reselect';
import createCompositeRecord from '../../helpers/createCompositeRecord';
import paginationHelper from '../../helpers/paginationHelper';
import recordCurrentState from '../../helpers/recordCurrentState';
import recordNextState from '../../helpers/recordNextState';
import recordsUpdated from './recordsUpdated.selector';
import recordsChanged from './recordsChanged.selector';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';
import paginationSelectorFactory from './dataTable/paginationFactory.selector';

export const selectorCompositeClaimRecordsToShow = createSelector(
  [
    paginationSelectorFactory('claim', 'currentPage'),
    paginationSelectorFactory('claim', 'numOfRecordsPerPage'),
    paginationSelectorFactory('property', 'openedPropertyIndex'),
    recordsUpdated,
    recordsChanged,
    columnAndRecordConfigsSelectorFactory('fieldTypesNumericalValues'),
    columnAndRecordConfigsSelectorFactory('claimFieldConfigsBasedOnState'),
    columnAndRecordConfigsSelectorFactory('claimColumnConfigsBasedOnState')
  ],
  (
    currentClaimsPage,
    numOfClaimRecordsPerPage,
    openedPropertyIndex,
    recordsUpdated,
    recordsChanged,
    fieldTypesNumericalValues,
    claimFieldConfigsBasedOnState,
    claimColumnConfigsBasedOnState
  ) => {
    if (
      !Number.isInteger(openedPropertyIndex) ||
      recordsUpdated.length === 0 ||
      recordsUpdated[openedPropertyIndex]['claims'].length === 0
    ) {
      return [];
    }

    const numericsToFieldTypes = _.invert(fieldTypesNumericalValues);

    const { startAt, endAt } = paginationHelper({
      currentPage: currentClaimsPage,
      numOfRecordsPerPage: numOfClaimRecordsPerPage,
      totalNumOfRecords: recordsUpdated[openedPropertyIndex]['claims'].length
    });

    const propertyRecord =
      recordsUpdated[openedPropertyIndex][FIELD_CATEGORY_PROPERTY];
    const propertyRecordChanges =
      recordsChanged[openedPropertyIndex][FIELD_CATEGORY_PROPERTY];

    const toShow = [];

    for (let i = startAt, iMax = endAt; i < iMax; i++) {
      const record = recordsUpdated[openedPropertyIndex]['claims'][i];
      const recordChanges = recordsChanged[openedPropertyIndex]['claims'][i];

      toShow.push(
        createCompositeRecord({
          currentState: recordCurrentState(
            propertyRecord,
            propertyRecordChanges
          ),
          nextState: recordNextState(propertyRecord, propertyRecordChanges),
          record,
          recordChanges,
          columnConfigs: claimColumnConfigsBasedOnState,
          numericsToFieldTypes,
          fieldConfigsBasedOnState: claimFieldConfigsBasedOnState
        })
      );
    }

    return toShow;
  }
);

export default selectorCompositeClaimRecordsToShow;
