import _ from 'lodash';
import { createSelector } from 'reselect';
import columnAndRecordConfigsSelectorFactory from './dataTable/columnAndRecordConfigsFactory';

export const selectorNumericsToFieldTypes = createSelector(
  [columnAndRecordConfigsSelectorFactory('fieldTypesNumericalValues')],
  fieldTypesNumericalValues => {
    const final = {};

    _.forEach(fieldTypesNumericalValues, (numericValue, fieldType) => {
      final[numericValue] = fieldType;
    });

    return final;
  }
);

export default selectorNumericsToFieldTypes;
