export default (
    buildingsDeclaredValue, 
    buildingsRate, 
    contentsSumInsured, 
    contentsRate, 
    rentSumInsured,
    rentRate,
    serviceChargeSumInsured,
    serviceChargeRate,
    propertyOwnersSumInsured,
    propertyOwnersLiabilityRate
) => {
    const sum = (buildingsDeclaredValue * buildingsRate) + (contentsSumInsured * contentsRate) + (rentSumInsured * rentRate) + (serviceChargeSumInsured * serviceChargeRate) + (propertyOwnersSumInsured * propertyOwnersLiabilityRate)

    return sum / 100
    
    // Math.round(((sum / 100) + Number.EPSILON) * 100) / 1000    
}
